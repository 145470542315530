import { Box3, Matrix4, Quaternion, Vector3 } from "three";
import { v4 as uuidv4 } from "uuid";
import { VisibleNodesStrategy } from "../Lod";
import { LodNodeFetch, LodTree } from "../Lod/LodTree";
import { invertedRigid } from "../Utils";
import { loadWorker } from "../Utils/Workers";
import { PotreeNode } from "./PotreeNode";
import { PotreeNodeFetch } from "./PotreeNodeFetch";
import { PotreeMetadata, loadHierarchy } from "./PotreeTypes";
import { PotreeVisibleNodesStrategy } from "./PotreeVisibleNodesStrategy";

/** List of urls needed to retrieve data from the backend */
export type PotreeUrls = {
	/** URL to the metadata json */
	metadata: URL;
	/** URL to the hierarchy binary file */
	hierarchy: URL;
	/** URL to the point cloud binary data file */
	octree: URL;
};

/**
 * Potree implementation of a LOD tree
 */
export class PotreeTree implements LodTree<PotreeNode> {
	#root: PotreeNode;
	#worldMatrix = new Matrix4();
	#worldMatrixInverse = new Matrix4();
	#nodes = new Array<PotreeNode>();
	#uuid = "";
	#treeMaxDepth = 1;
	visibleNodesStrategy: VisibleNodesStrategy = new PotreeVisibleNodesStrategy();
	#url: PotreeUrls;
	#boundingBox: Box3 = new Box3();

	/** @returns true if this PointCloud is mono chromatic */
	get monochrome(): boolean {
		const colorAttribute = this.metadata.attributes.find((a) => a.name === "rgb");

		return !colorAttribute || new Vector3(...colorAttribute.min).equals(new Vector3(...colorAttribute.max));
	}

	/**
	 * Create a tree starting from the root node
	 *
	 * @param url The url pointing to the storage of the tree data
	 * @param metadata The metadata describing the tree
	 * @param root The root node of the tree
	 * @param pcBackendUrl The url of the point cloud backend to use to optimize data range requests
	 */
	constructor(
		url: PotreeUrls,
		public metadata: PotreeMetadata,
		root: PotreeNode,
		public pcBackendUrl?: string,
	) {
		this.#url = url;
		this.#root = root;
		this.#nodes = [root];
		this.#uuid = uuidv4();

		const positionAttribute = metadata.attributes.filter((a) => a.name === "position")[0];
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (positionAttribute) {
			const points = [
				positionAttribute.min[0],
				positionAttribute.min[1],
				positionAttribute.min[2],
				positionAttribute.max[0],
				positionAttribute.max[1],
				positionAttribute.max[2],
			];
			this.#boundingBox.setFromArray(points);
		}
	}

	/** @returns the root node */
	get root(): PotreeNode {
		return this.#root;
	}

	/** @returns the max depth of this tree */
	get maxDepth(): number {
		return this.#treeMaxDepth;
	}

	/** @returns this tree position vector */
	get position(): Vector3 {
		return new Vector3().setFromMatrixPosition(this.#worldMatrix);
	}

	/** @returns this tree quaternion */
	get quaternion(): Quaternion {
		return new Quaternion().setFromRotationMatrix(this.#worldMatrix);
	}

	/** @returns The tree pose matrix. */
	get worldMatrix(): Matrix4 {
		return this.#worldMatrix;
	}

	/** @returns the inverse pose of the tree. */
	get worldMatrixInverse(): Matrix4 {
		return this.#worldMatrixInverse;
	}

	/** @returns the total number of nodes in this tree */
	get numNodes(): number {
		return this.#nodes.length;
	}

	/** @returns a list with all the nodes */
	get nodes(): PotreeNode[] {
		return this.#nodes;
	}

	/** @returns the UUID of the tree */
	get uuid(): string {
		return this.#uuid;
	}

	/** @returns the tree spacing */
	get spacing(): number {
		return this.metadata.spacing;
	}

	/**
	 * Get a node from an id
	 *
	 * @param id the id of the node we want
	 * @returns the node with the id passed
	 */
	getNode(id: number): PotreeNode {
		return this.#nodes[id];
	}

	/**
	 * @returns The strict bounding box of the point cloud
	 */
	get boundingBox(): Box3 {
		return this.#boundingBox;
	}

	/**
	 * Add new nodes to the tree
	 *
	 * @param nodes The new nodes to add
	 */
	addNodes(nodes: PotreeNode[]): void {
		this.#nodes.push(...nodes);
	}

	/**
	 * Get the points for a node
	 *
	 * @param nodeOrId The node or the node id to fetch the points for
	 * @returns An object to wait for the points or cancel the fetch
	 */
	async getNodePoints(nodeOrId: PotreeNode | number): Promise<LodNodeFetch> {
		const node = typeof nodeOrId === "number" ? this.getNode(nodeOrId) : nodeOrId;

		// Load node hierarchy if necessary
		if (node.isProxyNode) {
			await loadHierarchy(this.#url.hierarchy, this, node, this.pcBackendUrl);
		}

		const worker = await loadWorker("PotreeNodes");
		return new PotreeNodeFetch(this, node, this.#url.octree, worker, this.pcBackendUrl);
	}

	/**
	 * Sets the global pose of the tree
	 *
	 * @param m The global pose
	 */
	setWorldMatrix(m: Matrix4): void {
		this.#worldMatrix = m;
		this.#worldMatrixInverse = invertedRigid(m, this.#worldMatrixInverse);
	}
}
