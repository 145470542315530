import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementDataSetPCloudUpload,
  isIElementSectionDataFlash,
  isIElementSectionDataSetEls,
  isIElementSectionDataSetFocus,
  isIElementSectionDataSetPhotogrammetry,
  isIElementSectionDataSetWs,
  isIElementSectionFocusScan,
  isIElementSectionGeoslam,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddEmptyLayerSection extends BaseMutation {
  /** New area section element */
  newElement: CreateIElement<IElementSection>;
}

interface CreateMutationAddEmptyLayerSection {
  /** ID to give to the layer that will be created */
  layerId: GUID;

  /** Name of the area being created */
  name: string;

  /** ID of the root element */
  rootId: GUID;

  /**
   * ID of the section to append the layer to
   *
   * Must have one of the following type hints:
   *  * DataSetPCloudUpload
   *  * DataSetGeoSlam
   *  * DataSetFocus
   *  * DataSetEls
   *  * DataSetWs
   *  * FocusScan
   *  * ElsScan
   *  * Flash
   *  * DataSetPhotogrammetry
   */
  sectionId: GUID;
}

/**
 * Creates a MutationAddEmptyLayerSection object with a new area element.
 *
 *```
 * Section (DataSetXXX)
 * └ Section (Layer)
 * ```
 *
 * @returns A MutationAddEmptyLayerSection object with a new area element.
 */
export function createMutationAddEmptyLayerSection({
  layerId,
  rootId,
  sectionId,
  name,
}: CreateMutationAddEmptyLayerSection): MutationAddEmptyLayerSection {
  const layer: CreateIElement<IElementSection> = {
    type: IElementType.section,
    typeHint: IElementTypeHint.layer,
    parentId: sectionId,
    id: layerId,
    rootId,
    name,
    childrenIds: [],
  };

  return {
    ...createBaseMutation(
      MutationTypes.MutationAddEmptyLayerSection,
      sectionId,
    ),
    newElement: layer,
  };
}

/**
 * @returns True if the given element is allowed for the MutationAddEmptyLayerSection mutation
 * @param element IElement to check
 */
export function isElementAllowedForFloorplanGeneration(
  element: IElement,
): boolean {
  return (
    isIElementDataSetPCloudUpload(element) ||
    isIElementSectionGeoslam(element) ||
    isIElementSectionDataSetFocus(element) ||
    isIElementSectionDataSetWs(element) ||
    isIElementSectionFocusScan(element) ||
    isIElementSectionDataFlash(element) ||
    isIElementSectionDataSetEls(element) ||
    isIElementSectionDataSetPhotogrammetry(element)
  );
}
