import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { blue, cyan, neutral, red } from "../colors";
import { ArrowDown2Icon } from "../icons";
import { InputLabelTag } from "../input/input-label-tag";
import { FaroText } from "../text/faro-text/faro-text";
import { NoTranslate } from "../translation";
import { DropdownProps } from "./dropdown-types";
import { SingleSelectItem } from "./single-select-item";

type Colors = {
  /** The color of the text in the dropdown */
  color: string;
  /** The color of the input label */
  inputColor: string;
  /** The color of the input label, if disabled */
  inputDisabledColor: string;
  /** The color of the select component */
  selectColor: string;
  /** The color of the select when the component has the focus */
  selectFocusColor: string;
  /** The background color of the select */
  selectBackgroundColor: string;
  /** The backgrounf color of the select, if disabled */
  selectDisabledBackgroundColor: string;
  /** The fill color of the input in the select, if disabled */
  selectInputFillDisabledColor: string;
  /** The color of the outline */
  outlineColor: string;
  /** The color of the outline when hovering */
  outlineHoverColor: string;
  /** The color of the outline when the component has the focus */
  outlineFocusColor: string;
  /** The color of the outline in case of error */
  outlineErrorColor: string;
  /** The color of the icon in the select */
  selectIconColor: string;
  /** The color of the icon in the select, if disabled */
  selectIconDisabledColor: string;
  /** The color of text in the paper */
  paperColor: string;
  /** The background color of the paper */
  paperBackgroundColor: string;
  /** The color of the shadow of the paper */
  paperShadowColor: string;
  /** The color of the border of the paper */
  paperBorderColor: string;
  /** The color of the help text */
  helpColor: string;
};

const DARK_COLORS: Colors = {
  color: neutral[100],
  inputColor: neutral[100],
  inputDisabledColor: neutral[500],
  selectColor: neutral[100],
  selectFocusColor: neutral[100],
  selectBackgroundColor: neutral[999],
  selectDisabledBackgroundColor: neutral[900],
  selectInputFillDisabledColor: neutral[500],
  outlineColor: neutral[500],
  outlineHoverColor: neutral[100],
  outlineFocusColor: cyan[400],
  outlineErrorColor: red[300],
  selectIconColor: neutral[100],
  selectIconDisabledColor: neutral[500],
  paperColor: neutral[100],
  paperBackgroundColor: neutral[999],
  paperShadowColor: neutral[500],
  paperBorderColor: neutral[800],
  helpColor: neutral[300],
};

const LIGHT_COLORS: Colors = {
  color: neutral[800],
  inputColor: neutral[800],
  inputDisabledColor: neutral[500],
  selectColor: neutral[500],
  selectFocusColor: neutral[800],
  selectBackgroundColor: neutral[0],
  selectDisabledBackgroundColor: neutral[100],
  selectInputFillDisabledColor: neutral[500],
  outlineColor: neutral[500],
  outlineHoverColor: neutral[800],
  outlineFocusColor: blue[500],
  outlineErrorColor: red[500],
  selectIconColor: neutral[800],
  selectIconDisabledColor: neutral[500],
  paperColor: neutral[800],
  paperBackgroundColor: neutral[0],
  paperShadowColor: neutral[500],
  paperBorderColor: neutral[200],
  helpColor: neutral[600],
};

function Placeholder({
  placeholder,
}: Pick<DropdownProps, "placeholder">): JSX.Element {
  return (
    <FaroText
      variant="bodyM"
      sx={{
        fontStyle: "italic",
        mr: 1,
        color: neutral[500],
      }}
    >
      {placeholder ?? "Select option"}
    </FaroText>
  );
}

/** @returns The select component of the dropdown */
export function DropdownSelect({
  value: selectedValue,
  label,
  disabled: isDisabled,
  error: isError,
  sx,
  fullWidth = true,
  children,
  dark,
  helpText,
  tag,
  ...props
}: Omit<
  DropdownProps,
  "options" | "shouldCapitalize" | "placeholder"
>): JSX.Element {
  const { spacing } = useTheme();

  const CustomIconComponent = useCallback((props: object) => {
    return <ArrowDown2Icon sx={{ marginRight: 1 }} {...props} />;
  }, []);

  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel shrink>
          <FaroText
            variant="labelM"
            sx={{
              color: isDisabled ? colors.inputDisabledColor : colors.inputColor,
              "&.Mui-focused": {
                color: colors.inputColor,
              },
            }}
          >
            {label}
          </FaroText>
          {tag && <InputLabelTag tag={tag} dark={dark} />}
        </InputLabel>
      )}

      <Select
        {...props}
        displayEmpty
        value={selectedValue}
        disabled={isDisabled}
        error={isError}
        IconComponent={CustomIconComponent}
        sx={{
          ...sx,
          color: colors.selectColor,
          backgroundColor: colors.selectBackgroundColor,
          "&.Mui-focused": {
            color: colors.selectFocusColor,
          },
          "& .MuiInputBase-input": {
            py: 1.25,
          },

          // Update border color based on state
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.outlineColor,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.outlineHoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.outlineFocusColor,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.outlineErrorColor,
          },

          // Disabled style
          "&.Mui-disabled": {
            backgroundColor: colors.selectDisabledBackgroundColor,
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          },
          "& .MuiInputBase-input.Mui-disabled, .MuiInputBase-input.Mui-error": {
            WebkitTextFillColor: colors.selectInputFillDisabledColor,
          },

          "label + &": {
            marginTop: spacing(3),
          },
          ".MuiSelect-icon": {
            color: isDisabled
              ? colors.selectIconDisabledColor
              : colors.selectIconColor,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: { marginTop: 0.5 },
          PaperProps: {
            sx: {
              color: colors.paperColor,
              backgroundColor: colors.paperBackgroundColor,
              boxShadow: "none",
              border: `1px solid ${colors.paperBorderColor}`,

              "& .MuiList-root": {
                display: "flex",
                flexFlow: "column",
                p: 0.5,
                gap: 0.5,
              },
            },
          },
        }}
      >
        {children}
      </Select>
      {(!!isError || !!helpText) && (
        <FormHelperText sx={{ marginY: 0.5, marginX: "1px" }}>
          <FaroText
            variant="bodyS"
            sx={{
              color: isError ? colors.outlineErrorColor : colors.helpColor,
              opacity: isDisabled ? "50%" : "100%",
            }}
          >
            {isError ? "Enter a valid selection" : helpText}
          </FaroText>
        </FormHelperText>
      )}
    </FormControl>
  );
}

/**
 * @returns The reusable dropdown component styled according to the design guidelines
 */
export function Dropdown({
  dark,
  options,
  placeholder,
  value: selectedValue,
  label,
  disabled,
  error,
  sx,
  helpText,
  fullWidth = true,
  shouldCapitalize = true,
  ...props
}: DropdownProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  return (
    <DropdownSelect
      value={selectedValue}
      label={label}
      disabled={disabled}
      error={error}
      sx={sx}
      fullWidth={fullWidth}
      dark={dark}
      helpText={helpText}
      {...props}
      renderValue={(selected) => {
        if (!selected) {
          return <Placeholder placeholder={placeholder} />;
        }

        const option = options.find((o) => o.value === selected);

        return (
          <FaroText
            component="div"
            variant="bodyM"
            sx={{
              color: colors.color,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: shouldCapitalize ? "capitalize" : "none",
              mr: 1.5,
            }}
          >
            {option?.label ?? <NoTranslate>{selected}</NoTranslate>}
          </FaroText>
        );
      }}
    >
      {
        // This invisible item is used to avoid having the first element of the list always focused
        // when the menu is opened and no value has been selected yet.
        // This is a bug in MaterialUI https://github.com/mui/material-ui/issues/23747
        //
      }
      <MenuItem sx={{ display: "none" }} />
      {options.map(
        ({ key, value, label, secondaryText, isDisabled, isHeader }, index) =>
          isHeader ? (
            <ListSubheader key={key} sx={{ backgroundColor: "transparent" }}>
              {index !== 0 && <Divider />}

              <FaroText
                component="div"
                variant="bodyM"
                sx={{
                  color: colors.color,
                  fontSize: "0.75rem",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  mt: 1,
                }}
              >
                {label}
              </FaroText>
            </ListSubheader>
          ) : (
            <SingleSelectItem
              key={key}
              value={value}
              label={label}
              secondaryText={secondaryText}
              disabled={isDisabled}
              shouldCapitalize={shouldCapitalize}
              selectedValue={selectedValue}
              dark={dark}
            />
          ),
      )}
    </DropdownSelect>
  );
}
