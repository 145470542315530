import { GUID, IElementBase, ISOTimeString } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Change the name of an Element
 */
export interface MutationSetElementName extends BaseMutation {
  type: MutationTypes.MutationSetElementName;

  /** The new name of the element */
  value: IElementBase["name"];
}

/**
 * @returns a mutation to change an IElement name
 * @param elementId id of the element to rename
 * @param value new name of the element
 */
export function createMutationSetElementName(
  elementId: GUID,
  value: IElementBase["name"],
): MutationSetElementName {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementName, elementId),
    value,
  };
}

/**
 * Change the createdAt time of an Element
 */
export interface MutationSetElementCreatedAt extends BaseMutation {
  type: MutationTypes.MutationSetElementCreatedAt;

  /** The new createdAt time of the element */
  value: IElementBase["createdAt"];
}

/**
 * @returns a mutation to change an Element's createdAt time
 * @param elementId id of the element
 * @param value new createdAt time of the element
 */
export function createMutationSetElementCreatedAt(
  elementId: GUID,
  value: ISOTimeString,
): MutationSetElementCreatedAt {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementCreatedAt, elementId),
    value,
  };
}

/**
 * Change the description of an Element
 */
export interface MutationSetElementDescription extends BaseMutation {
  type: MutationTypes.MutationSetElementDescription;

  /** The new description of the element */
  value: IElementBase["descr"];
}

/**
 * @returns a mutation to change an IElement description
 * @param elementId id of the element
 * @param value new description of the element
 */
export function createMutationSetElementDescription(
  elementId: GUID,
  value: string,
): MutationSetElementDescription {
  return {
    ...createBaseMutation(
      MutationTypes.MutationSetElementDescription,
      elementId,
    ),
    value: value || null,
  };
}
