import { sendAuthenticatedJsonRequest, TokenProvider } from "../authentication";
import { PostTopic } from "./bcf-services-api-client-parameters";
import {
  GetProject,
  GetProjectExtensions,
  GetTopic,
} from "./bcf-services-api-responses";
import { BcfServicesIntegrationType } from "./bcf-services-api-types";
import {
  isGetAllProjects,
  isGetAllTopics,
  isGetProject,
  isGetProjectExtensions,
  isGetTopic,
} from "./bcf-services-api-validation-functions";

/**
 * The version of the BcfServicesApi that this client is using.
 */
export const BCF_SERVICES_API_DEFAULT_VERSION = "4-draft.2024.02";

/**
 * A class to query the BcfServicesApi.
 */
export class BcfServicesApiClient {
  /**
   * Create a new BcfServicesApi Client
   *
   * @param endpoint The URL for the BcfServicesApi instance to use
   * @param tokenProvider To get authentication tokens for this project
   */
  constructor(
    private endpoint: string,
    private tokenProvider: TokenProvider,
  ) {}

  /**
   *
   * @param integration The third party service name
   * @returns The list of all projects available
   */
  async getAllProjects(
    integration: BcfServicesIntegrationType,
  ): Promise<GetProject[]> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects`,
      httpMethod: "GET",
      tokenProvider: this.tokenProvider,
      typeGuard: isGetAllProjects,
    });
  }

  /**
   *
   * @param integration The third party service name
   * @param projectId The ID of the project to retrieve
   * @returns The project with the corresponding project ID
   */
  async getProject(
    integration: BcfServicesIntegrationType,
    projectId: string,
  ): Promise<GetProject> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects/${projectId}`,
      httpMethod: "GET",
      tokenProvider: this.tokenProvider,
      typeGuard: isGetProject,
    });
  }

  /**
   * Retrieves the project extensions for the current integration and project.
   *
   * @param integration The third party service name
   * @param projectId The ID of the project to retrieve
   * @returns A promise that resolves to a GetProjectExtensions object.
   */
  async getProjectExtensions(
    integration: BcfServicesIntegrationType,
    projectId: string,
  ): Promise<GetProjectExtensions> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects/${projectId}/extensions`,
      httpMethod: "GET",
      tokenProvider: this.tokenProvider,
      typeGuard: isGetProjectExtensions,
    });
  }

  /**
   * Retrieves all topics from the BCF Services API.
   *
   * @param integration The third party service name
   * @param projectId The ID of the project to retrieve
   * @returns A promise that resolves to a GetAllTopicsResponse object.
   */
  async getAllTopics(
    integration: BcfServicesIntegrationType,
    projectId: string,
  ): Promise<GetTopic[]> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects/${projectId}/topics`,
      httpMethod: "GET",
      tokenProvider: this.tokenProvider,
      typeGuard: isGetAllTopics,
    });
  }

  /**
   * Creates a new topic.
   *
   * @param integration The third party service name
   * @param projectId The ID of the project to retrieve
   * @param topicData - The data for the new topic.
   * @returns A promise that resolves to the response containing the created topic.
   */
  async createTopic(
    integration: BcfServicesIntegrationType,
    projectId: string,
    topicData: PostTopic,
  ): Promise<GetTopic> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects/${projectId}/topics`,
      httpMethod: "POST",
      requestBody: topicData,
      tokenProvider: this.tokenProvider,
      typeGuard: isGetTopic,
    });
  }

  /**
   * Retrieves a topic by its GUID.
   *
   * @param integration The third party service name
   * @param projectId The ID of the project to retrieve
   * @param topicGuid - The GUID of the topic to retrieve.
   * @returns A promise that resolves to a GetTopicResponse object.
   */
  async getTopic(
    integration: BcfServicesIntegrationType,
    projectId: string,
    topicGuid: string,
  ): Promise<GetTopic> {
    return await sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/${integration}/bcf/${BCF_SERVICES_API_DEFAULT_VERSION}/projects/${projectId}/topics/${topicGuid}`,
      httpMethod: "GET",
      tokenProvider: this.tokenProvider,
      typeGuard: isGetTopic,
    });
  }
}
