import { Menu, MenuProps } from "@mui/material";
import { forwardRef } from "react";
import { ColorString, neutral } from "../colors";

type FaroMenuColors = {
  backgroundColor?: ColorString;
  borderColor: ColorString;
  shadowColor?: ColorString;
};

const LIGHT_COLORS: Readonly<FaroMenuColors> = Object.freeze({
  backgroundColor: undefined,
  borderColor: neutral[200],
  shadowColor: neutral[500],
});

const DARK_COLORS: Readonly<FaroMenuColors> = Object.freeze({
  backgroundColor: neutral[950],
  borderColor: neutral[800],
  shadowColor: undefined,
});

export type FaroMenuProps = MenuProps & {
  /** True to use the dark styling for the FaroMenu */
  dark?: boolean;
};

/** @returns a Menu implementation following the FARO design system  */
export const FaroMenu = forwardRef<HTMLDivElement, FaroMenuProps>(
  function FaroMenu({ children, dark = false, sx, ...rest }, ref): JSX.Element {
    const colors = dark ? DARK_COLORS : LIGHT_COLORS;

    return (
      <Menu
        {...rest}
        ref={ref}
        sx={[
          {
            ".MuiPaper-root": {
              boxShadow: colors.shadowColor
                ? `0px 3px 6px ${colors.shadowColor}33`
                : "none",
            },
            ".MuiMenu-paper": {
              background: colors.backgroundColor,
              border: `1px solid ${colors.borderColor}`,
              minWidth: "250px",
            },
            ".MuiMenu-list": {
              m: 0,
              p: 0.5,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Menu>
    );
  },
);
