import {
	BoxGeometry,
	BoxHelper,
	Camera,
	ColorRepresentation,
	CylinderGeometry,
	Euler,
	Group,
	Matrix4,
	Mesh,
	MeshBasicMaterial,
	Plane,
	Raycaster,
	SphereGeometry,
	Vector3,
} from "three";
import { memberWithPrivateData } from "../../Utils";
import { BoxSide, BoxSideState } from "./BoxSide";
import { Gizmo } from "./Gizmo";
import {
	DEFAULT_X_COLORS,
	DEFAULT_Y_COLORS,
	DEFAULT_Z_COLORS,
	GizmoHandle,
	GizmoHandleColors,
	GizmoHandleState,
} from "./GizmoHandle";

// Size for the handle
const HANDLE_RADIUS = 1;

// The size for the handle border
const HANDLE_BORDER_SIZE = 0.1;

/** The size of the handle in pixels */
const HANDLE_SIZE = 10;

/** Minimum size of the clipping box. Must be greater than 0 to avoid numerical errors. */
const MIN_BOX_SIZE = 0.01;

/** The colors for the BoxControls */
export type BoxGizmoColors = {
	/** The base color of the box */
	box: ColorRepresentation;
} & GizmoHandleColors;

/** Defaults to faro blue theme colors */
export const DEFAULT_COLORS: BoxGizmoColors = {
	box: 0x6699ff,
	default: 0x1f65f0,
	hovered: 0x0e4ecc,
	focused: 0x113c9d,
};

/** The gizmo to manipulate a box by interacting with the 6 sides */
export class BoxGizmo extends Gizmo {
	handles: BoxHandle[] = [];
	sides: BoxSide[] = [];

	/**
	 *
	 * @param element The HTML element on which the scene is rendered
	 * @param camera The camera used in the scene
	 * @param gizmoColors The colors used for the different states of the gizmo
	 */
	constructor(element: HTMLElement, camera: Camera, gizmoColors?: Partial<BoxGizmoColors>) {
		super(element, camera);

		// Create the spherical handles and the planar sides meshes
		const sideTransforms: Array<[Vector3, Euler, BoxGizmoColors]> = [
			[
				new Vector3(0.5, 0, 0),
				new Euler(0, 0, -Math.PI / 2),
				{ ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_X_COLORS },
			],
			[
				new Vector3(-0.5, 0, 0),
				new Euler(0, 0, Math.PI / 2),
				{ ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_X_COLORS },
			],
			[new Vector3(0, 0.5, 0), new Euler(0, 0, 0), { ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_Y_COLORS }],
			[
				new Vector3(0, -0.5, 0),
				new Euler(0, 0, Math.PI),
				{ ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_Y_COLORS },
			],
			[
				new Vector3(0, 0, 0.5),
				new Euler(Math.PI / 2, 0, 0),
				{ ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_Z_COLORS },
			],
			[
				new Vector3(0, 0, -0.5),
				new Euler(-Math.PI / 2, 0, 0),
				{ ...DEFAULT_COLORS, ...gizmoColors, ...DEFAULT_Z_COLORS },
			],
		];

		for (const [position, rotation, colors] of sideTransforms) {
			const handle = new BoxHandle(position, rotation, colors);
			handle.matrixWorldAutoUpdate = false;
			handle.matrixAutoUpdate = false;
			this.handles.push(handle);
			this.add(handle);
		}
		this.add(new BoxHelper(new Mesh(new BoxGeometry(1, 1, 1)), gizmoColors?.box ?? DEFAULT_COLORS.box));
	}

	/** @inheritdoc */
	override updateMatrixWorld(force?: boolean | undefined): void {
		super.updateMatrixWorld(false);

		for (const handle of this.handles) {
			this.computeHandleMatrices(handle, force);
		}
	}

	/**
	 * Update the handle scale based on the current box configuration, so that
	 * the size remains the same indipendently from the camera
	 *
	 * @param handle The handle for which the new 3D configuration should be computed
	 */
	computeHandleMatrices = memberWithPrivateData(() => {
		const worldScale = new Vector3();
		const matrix = new Matrix4();
		const sideMatrix = new Matrix4();

		return (handle: BoxHandle, force?: boolean) => {
			// Apply the quaternion to the scale so that the sphere remain sphere even if the box is rotated
			matrix.makeRotationFromEuler(handle.rotation).premultiply(this.matrixWorld);
			worldScale.setFromMatrixScale(matrix);

			// Scale based on factor so that the handles' radius is always HANDLE_SIZE pixels
			const factor = this.computePixelsToMetersFactor(handle, HANDLE_SIZE);
			handle.scale.set(factor / worldScale.x, factor / worldScale.y, factor / worldScale.z);

			// Compute the worlds scale so that the sides of the box have the right size, taking
			// into account the plane local orientation
			sideMatrix.makeRotationFromEuler(handle.side.rotation).premultiply(matrix);
			worldScale.setFromMatrixScale(sideMatrix);
			handle.side.scale.set(worldScale.x / factor, worldScale.y / factor, worldScale.z / factor);

			handle.matrix.compose(handle.position, handle.quaternion, handle.scale);
			handle.updateMatrixWorld(force);
		};
	});
}

/**
 * A small mesh handle to edit a Box
 */
export class BoxHandle extends GizmoHandle {
	/** Name to recognize the Object in the scene graph */
	name = "BoxHandle";

	/** Mesh to render a border around the handle in focused state */
	#border = new BoxHandleBorder();

	/** The mesh representing the box face associated to the handle */
	side: BoxSide;

	/** Shared Geometry resource to use for all handles */
	static geometry = new SphereGeometry(HANDLE_RADIUS);

	/** Axis direction that the handle should manipulate. Cached for numerical stability. */
	#axis: Vector3;

	/** @returns the axis direction that the handle should manipulate. */
	get axis(): Vector3 {
		return this.#axis.clone();
	}
	/**
	 *
	 * @param position The position of the box relative to the parent
	 * @param rotation The rotation of the box relative to the parent
	 * @param colors The colors to use with the BoxHandle
	 */
	constructor(position: Vector3, rotation: Euler, colors?: Partial<BoxGizmoColors>) {
		super(BoxHandle.geometry, position, rotation, { ...DEFAULT_COLORS, ...colors });
		this.#axis = position.clone().normalize();
		this.add(this.#border);

		const sideRotation = new Euler(-Math.PI * 0.5, 0, 0);
		this.side = new BoxSide(new Vector3(), sideRotation, { ...DEFAULT_COLORS, ...colors }.box);
		this.add(this.side);

		this.state = GizmoHandleState.default;
	}

	/** changes the current interaction state of the BoxHandle */
	override set state(state: GizmoHandleState) {
		super.setState(state);

		switch (state) {
			case GizmoHandleState.default: {
				this.side.state = BoxSideState.default;
				this.border.visible = false;
				break;
			}
			case GizmoHandleState.focused:
			case GizmoHandleState.hovered: {
				this.side.state = BoxSideState.hovered;
				this.border.visible = true;
				break;
			}
		}
	}

	/** @returns the current interaction state of the BoxHandle */
	get state(): GizmoHandleState {
		return super.state;
	}

	/**
	 * Compute the new position of the handle after the user dragged it
	 *
	 * @param group The group manipulated by the gizmo
	 * @param raycaster The raycaster used during the interaction
	 */
	onMouseDrag = memberWithPrivateData(() => {
		const currentAxis = new Vector3();
		const matrix = new Matrix4();

		const normal = new Vector3();
		const endPoint = new Vector3();
		const plane = new Plane();

		const movement = new Vector3();

		return (group: Group, startPoint: Vector3, raycaster: Raycaster) => {
			if (!this.parent) return;
			const gizmo = this.parent;

			// Compute the world direction of the normal of the plane associated to this handle
			currentAxis.set(0, 1, 0).applyMatrix4(matrix.extractRotation(this.matrixWorld)).normalize();
			// Compute the normal of the interaction plane: it's orthogonal to the current axis
			normal.set(currentAxis.x, currentAxis.y, currentAxis.z).cross(raycaster.ray.direction).normalize();
			normal.cross(currentAxis).normalize();

			// Compute the current 3d world coordinates of the mouse
			plane.setFromNormalAndCoplanarPoint(normal, startPoint);
			raycaster.ray.intersectPlane(plane, endPoint);

			// Compute how much the mouse moved in 3d world and project it on the current axis
			movement.subVectors(endPoint, startPoint);
			const distance = movement.dot(normal.set(currentAxis.x, currentAxis.y, currentAxis.z));

			// Compute the displacement in the group local coordinates
			currentAxis
				.set(0, 1, 0)
				.applyMatrix4(matrix.extractRotation(this.matrix))
				.applyMatrix4(matrix.makeRotationFromQuaternion(gizmo.quaternion))
				.normalize();
			const displacement = currentAxis.multiplyScalar(distance);
			// Take into account the sign of the displacement so that the box can be properly scaled/moved
			const sgnX = Math.sign(displacement.x);
			const sgnY = Math.sign(displacement.y);
			const sgnZ = Math.sign(displacement.z);

			if (distance > 0) {
				// If distance is more than 0, we are dragging the plane to the exterior, increasing the box size
				group.scale.x += sgnX * displacement.x;
				group.scale.y += sgnY * displacement.y;
				group.scale.z += sgnZ * displacement.z;
			} else {
				// If distance is less than 0, we are dragging the plane to the interior, decreasing the box size
				if (sgnX * displacement.x > group.scale.x - MIN_BOX_SIZE) return;
				if (sgnY * displacement.y > group.scale.y - MIN_BOX_SIZE) return;
				if (sgnZ * displacement.z > group.scale.z - MIN_BOX_SIZE) return;
				group.scale.x -= sgnX * displacement.x;
				group.scale.y -= sgnY * displacement.y;
				group.scale.z -= sgnZ * displacement.z;
			}

			// Update the start point for the next iteration
			startPoint.copy(endPoint);

			// Move the center of the box by half of the scale increase, taking into account the local orientation
			// of the box and the UP axis of the gizmo
			displacement.multiplyScalar(0.5).applyMatrix4(matrix.makeRotationFromQuaternion(group.quaternion));
			group.position.add(displacement);
		};
	});

	/** @returns The mesh used for the handle border */
	get border(): BoxHandleBorder {
		return this.#border;
	}
}

/**
 * A small sub-mesh of the BoxHandle to render a border
 */
class BoxHandleBorder extends Mesh {
	/** Shared Material resource for all BoxHandleBorders */
	static material = new MeshBasicMaterial({ color: 0xffffff });

	/** Shared Geometry resource for all BoxHandleBorders */
	static geometry = new CylinderGeometry(
		HANDLE_RADIUS + HANDLE_BORDER_SIZE,
		HANDLE_RADIUS + HANDLE_BORDER_SIZE,
		0.1,
		32,
	);

	constructor() {
		super();

		this.geometry = BoxHandleBorder.geometry;
		this.material = BoxHandleBorder.material;
	}

	/** Disable raycasts on the border object so only the actual parent handle is hit */
	raycast(): void {}
}
