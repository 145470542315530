import {
	ColorRepresentation,
	DoubleSide,
	Euler,
	Material,
	Mesh,
	MeshBasicMaterial,
	PlaneGeometry,
	Vector3,
} from "three";

/** Interaction states for the BoxSide */
export enum BoxSideState {
	default = "default",
	hovered = "hovered",
}

/**
 * Mesh for a single side of the BoxControls box
 */
export class BoxSide extends Mesh {
	/** The default name of the 3D object */
	name = "BoxSide";
	/**  Default material for a box side */
	defaultMat: Material;
	/** Material used when the box side is hovered */
	hoveredMat: Material;

	/** Geometry used to render a side of the box */
	static geometry = new PlaneGeometry(1, 1);

	/** The currently rendered interaction state of the box handle */
	#state = BoxSideState.default;

	/**
	 * @param position The position of the box relative to the parent
	 * @param rotation The rotation of the box relative to the parent
	 * @param color The color to use for the box side
	 */
	constructor(position: Vector3, rotation: Euler, color: ColorRepresentation) {
		super();

		this.geometry = BoxSide.geometry;

		this.defaultMat = new MeshBasicMaterial({ color, opacity: 0.2, transparent: true, depthWrite: false });
		this.hoveredMat = new MeshBasicMaterial({
			color,
			opacity: 0.6,
			transparent: true,
			side: DoubleSide,
			depthWrite: false,
		});
		this.material = this.defaultMat;

		this.position.copy(position);
		this.rotation.copy(rotation);

		this.state = BoxSideState.default;
	}

	/** changes the current interaction state of the BoxHandle */
	set state(state: BoxSideState) {
		this.#state = state;

		switch (this.#state) {
			case BoxSideState.default:
				this.material = this.defaultMat;
				break;
			case BoxSideState.hovered:
				this.material = this.hoveredMat;
				break;
		}
	}

	/** @returns the current interaction state of the BoxHandle */
	get state(): BoxSideState {
		return this.#state;
	}

	/** Disable raycasts on the side object so only the actual parent handle is hit */
	raycast(): void {}
}
