import { assert } from "@faro-lotv/foundation";
import { BufferAttribute, BufferGeometry, Color, Plane, Points, Vector3 } from "three";
import { ColormapPointsMaterial } from "../Materials/ColormapPointsMaterial";

/** Colormap key value and color pair */
export type ColormapKey = {
	// Scale value within range of [0, 1]
	value: number;
	// Color associated with the value
	color: Color;
};

/**
 * Colormap defined as a list of colormap keys.
 * A proper colormap should be defined as:
 *  - At least 2 keys
 *  - The first key value should be 0, and last one should be 1
 *  - All key values should be in non-decreasing order, as every next value is greater than
 *    or equal to the previous one
 *  - Two consecutive equal values with different colors will result in a color jump
 */
export type Colormap = ColormapKey[];

// Check if user provided colormap are defined properly
function isColormapValid(colormap: Colormap): boolean {
	if (colormap.length < 2) return false;
	if (colormap[0].value !== 0) return false;
	if (colormap[colormap.length - 1].value !== 1) return false;

	for (let i = 0; i < colormap.length - 1; i++) {
		if (colormap[i].value > colormap[i + 1].value) return false;
	}
	return true;
}

/**
 * Check if two colormaps are equal
 *
 * @param colormap1 The first colormap to compare
 * @param colormap2 The second colormap to compare
 * @returns True if the two colormaps are equal
 */
export function areColormapsSame(colormap1: Colormap, colormap2: Colormap): boolean {
	if (colormap1.length !== colormap2.length) return false;
	for (let i = 0; i < colormap1.length; i++) {
		if (colormap1[i].value !== colormap2[i].value) return false;
		if (!colormap1[i].color.equals(colormap2[i].color)) return false;
	}
	return true;
}

/**
 * Class that renders points using a customizable colormap.
 *
 * For each point, its deviation to a reference plane is used to compute a scale
 * within the deviation range, then this scale is used to find the interpolated
 * color in the colormap.
 */
export class ColormapPoints extends Points<BufferGeometry, ColormapPointsMaterial> {
	#referencePlane = new Plane();
	#colormap: Colormap = [];

	/**
	 * Create colormap points from input data
	 *
	 * @param positions The input points position data, array of [x,y,z,x,y,z...] relative to a origin point
	 * @param origin The origin point
	 * @param referencePlane The reference plane in same coordinates system of the origin
	 * @param colormap The colormap to use
	 * @param minColorDeviation The deviation value associated with the minimum color range
	 * @param maxColorDeviation The deviation value associated with the maximum color range
	 */
	constructor(
		positions: Float32Array,
		origin: Vector3,
		referencePlane: Plane,
		colormap: Colormap,
		minColorDeviation: number,
		maxColorDeviation: number,
	) {
		assert(positions.length > 0 && positions.length % 3 === 0, "Invalid positions array");
		assert(minColorDeviation <= maxColorDeviation, "Invalid deviation range");

		const geometry = new BufferGeometry();
		geometry.setAttribute("position", new BufferAttribute(positions, 3));
		geometry.computeBoundingBox();
		const material = new ColormapPointsMaterial();
		super(geometry, material);

		this.position.copy(origin);

		this.#setReferencePlane(referencePlane);
		this.#setColormap(colormap);
		this.material.uniforms.minDeviation.value = minColorDeviation;
		this.material.uniforms.maxDeviation.value = maxColorDeviation;
	}

	/** @returns The size of the point */
	get pointSize(): number {
		return this.material.size;
	}

	/**
	 * Set the size of the point
	 *
	 * @param value The size of the point
	 */
	set pointSize(value: number) {
		this.material.size = value;
	}

	/** @returns The deviation value associated with the maximum color range */
	get maxColorDeviation(): number {
		return this.material.uniforms.maxDeviation.value;
	}

	/**
	 * Set the deviation value associated with the maximum color range
	 *
	 *  @param value The deviation valueassociated with the maximum color range
	 */
	set maxColorDeviation(value: number) {
		this.material.uniforms.maxDeviation.value = value;
	}

	/** @returns The deviation value associated with the minimum color range */
	get minColorDeviation(): number {
		return this.material.uniforms.minDeviation.value;
	}

	/**
	 * Set the deviation value associated with the minimum color range
	 *
	 * @param value The deviation value associated with the minimum color range
	 */
	set minColorDeviation(value: number) {
		this.material.uniforms.minDeviation.value = value;
	}

	/** @returns The reference plane in world CS */
	get referencePlane(): Plane {
		return this.#referencePlane;
	}

	/**
	 * Set the reference plane
	 *
	 * @param plane The reference plane in world CS
	 */
	set referencePlane(plane: Plane) {
		if (!plane.equals(this.#referencePlane)) {
			this.#setReferencePlane(plane);
		}
	}

	/** @returns The colormap */
	get colormap(): Colormap {
		return this.#colormap;
	}

	/**
	 * Set the colormap to use
	 *
	 * @param colormap The colormap to use
	 */
	set colormap(colormap: Colormap) {
		if (!areColormapsSame(colormap, this.#colormap)) {
			this.#setColormap(colormap);
		}
	}

	#setReferencePlane(plane: Plane): void {
		this.#referencePlane = plane.clone();
		const normalizedPlane = this.#referencePlane.clone().normalize();
		// translate the plane to the same origin as the points
		normalizedPlane.translate(this.position.clone().negate());
		this.material.uniforms.referencePlane.value.set(
			normalizedPlane.normal.x,
			normalizedPlane.normal.y,
			normalizedPlane.normal.z,
			normalizedPlane.constant,
		);
	}

	#setColormap(colormap: Colormap): void {
		const colorKeysValid = isColormapValid(colormap);
		if (colorKeysValid) {
			this.#colormap = colormap.slice();
			const nbKeys = this.#colormap.length;
			this.material.defines.NUMBER_OF_COLOR_KEYS = nbKeys;
			const uniformData = new Float32Array(nbKeys * 4);
			for (let i = 0; i < nbKeys; i++) {
				const { value, color } = this.#colormap[i];
				uniformData[i * 4 + 0] = color.r;
				uniformData[i * 4 + 1] = color.g;
				uniformData[i * 4 + 2] = color.b;
				uniformData[i * 4 + 3] = value;
			}
			this.material.uniforms.colorKeys.value = uniformData;
		} else {
			console.warn("Invalid colormap, no color for the points.");
			this.#colormap.length = 0;
			this.material.defines.NUMBER_OF_COLOR_KEYS = 0;
		}
		this.material.needsUpdate = true;
	}

	/** Dispose all resources of this colormap */
	dispose(): void {
		this.geometry.dispose();
		this.material.dispose();
	}
}
