/**
 * @param file file to get am image for
 * @returns the Image object for the file
 */
// eslint-disable-next-line require-await -- FIXME
export async function parseImageFromFile(
  file: File,
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.onerror = reject;

    image.src = URL.createObjectURL(file);
  });
}
