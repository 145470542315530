import { Camera, Matrix4, Vector2 } from "three";
import { LodTree } from "./LodTree";

/**
 * Any visible nodes strategy returns a list of weighted nodes.
 * In fact, the list of visible nodes is sorted by some type of weight,
 * and the assumption is that the returned list is sorted by this weight.
 *
 * For example, in an LOD point cloud the weight may be the estimated size on screen
 * of the node, or the pixels per point of the given node seen from the current camera.
 */
export type WeightedNode = {
	/** The node ID in the tree */
	id: number;
	/** The weight of the node seen from the current camera */
	weight: number;
};

export enum OptimizationHint {
	/** The camera/viewport are changing, so a full recomputation is needed */
	cameraChanging = "CameraChanging",
	/** The camera/viewport are steady, therefore an optimized computation may be used */
	cameraSteady = "CameraSteady",
	/** The camera/viewport are changing, but they will be steady at the next frame */
	cameraChangingOnce = "CameraChangingOnce",
}

/**
 * This interface describes the shape of a pluggable strategy to be used for determining which nodes of a tree should be shown
 * given a camera and a screen resolution.
 */
export interface VisibleNodesStrategy {
	/** @returns The maximum amount of points to be loaded in GPU. */
	get maxPointsInGpu(): number;
	/**
	 * @param n The new value of max points in GPU.
	 */
	set maxPointsInGpu(n: number);

	/** @returns the target point density on screen, used to determine how many nodes to render from the LOD tree. */
	get targetPixelsPerPoint(): number;

	/**
	 * @param n the target point density on screen,
	 * used to determine how many nodes to render from the LOD tree. Should lie in the interval [0.05, 50]
	 */
	set targetPixelsPerPoint(n: number);

	/** Optimization hint */
	optimizationHint: OptimizationHint;

	/**
	 *Given a camera, this algorithm returns the list of nodes that are visible from the camera, sorted by node index.
	 *
	 * @param tree The tree on which to perform the computation
	 * @param cloudWorldMatrix The global pose of the LOD point cloud.
	 * Warning: the scale of the point cloud must be uniform.
	 * @param camera The camera from which the scene is being rendered.
	 * @param screenSize The current screen size, in pixels.
	 * @returns the sorted list of indices of the visible nodes
	 */
	compute(tree: LodTree, cloudWorldMatrix: Matrix4, camera: Camera, screenSize: Vector2): WeightedNode[];

	/** Each visible nodes strategy is clonable */
	clone(): VisibleNodesStrategy;
}
