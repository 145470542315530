import {
  PropOptional,
  validateArrayOf,
  validateNonEmptyString,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  IElement,
  IElementAiMaterialDetection,
  IElementAiWall,
  IElementClippingBox,
  IElementDateTimeMarkupField,
  IElementDateTimeMarkupFieldTemplate,
  IElementDropDownMarkupField,
  IElementDropDownMarkupFieldTemplate,
  IElementFloorLayout,
  IElementGenericPointCloud,
  IElementGroup,
  IElementImg360,
  IElementImgCube,
  IElementImgSheet,
  IElementImgSheetTiled,
  IElementMarkup,
  IElementMarkupAccIssue,
  IElementMarkupAccRfi,
  IElementMarkupBim360,
  IElementMarkupPolygon,
  IElementMarkupProcoreObservation,
  IElementMarkupProcoreRfi,
  IElementMeasurePolygon,
  IElementModel3D,
  IElementModel3dStream,
  IElementPointCloudStream,
  IElementPointCloudStreamWebShare,
  IElementProjectRoot,
  IElementUserDirectoryMarkupField,
  IElementUserDirectoryMarkupFieldTemplate,
  IElementVideo360,
  VALID_POINT_CLOUD_ELEMENT_TYPES,
} from "../i-element";
import { IElementType } from "../i-element-base";
import { ProjectSettings, SlideContainerMarkupSettings } from "../properties";
import {
  validateIElementBase,
  validateIElementWithFileUri,
  validateIElementWithPixelSize,
  validateIElementWithUri,
  validateImg360LevelsOfDetail,
  validateImgSheetLevelsOfDetail,
  validatePolygonPoint,
  validateVec3,
} from "./i-element-base-validation";
import { validateIElementWithType } from "./validation-utils";

function getType(element: unknown): IElementType | string | undefined {
  if (
    !!element &&
    typeof element === "object" &&
    "type" in element &&
    typeof element.type === "string"
  ) {
    return element.type;
  }
}

/**
 * Validate that an object is a valid IElement and has all the expected property for the specific IElement type
 *
 * After this validation the type can be safely narrowed down using the discrimination functions provided by the library
 *
 * IMPORTANT NOTE: this validation can be expensive, the suggested usage is to validate the data as soon as it's parsed
 * from an external source (ProjectApi client, json file) and then use the lightweight discrimination function in the application logic
 *
 * @param element to check
 * @returns true if it's a valid IElement
 */
export function validateIElement(element: unknown): element is IElement {
  const type = getType(element);
  if (!type) {
    return false;
  }
  // Deep validation of all IElement possible types that have specific properties
  switch (type) {
    case IElementType.projectRoot:
      return validateIElementProjectRoot(element);
    case IElementType.group:
      return validateIElementGroup(element);
    case IElementType.imgSheet:
      return validateIElementImgSheet(element);
    case IElementType.imgSheetTiled:
      return validateIElementImgSheetTiled(element);
    case IElementType.img360:
      return validateIElementImg360(element);
    case IElementType.imgCube:
      return validateIElementImgCube(element);
    case IElementType.pCloud:
    case IElementType.pointCloudLaz:
    case IElementType.pointCloudE57:
    case IElementType.pointCloudGeoSlam:
    case IElementType.pointCloudCpe:
      return validateIElementPointCloud(element);
    case IElementType.pointCloudStreamWebShare:
      return validateIElementPointCloudStreamWebShare(element);
    case IElementType.pointCloudStream:
      return validateIElementPointCloudStream(element);
    case IElementType.model3d:
      return validateIElementModel3d(element);
    case IElementType.model3dStream:
      return validateIElementModel3dStream(element);
    case IElementType.aiMaterialDetection:
      return validateIElementAiMaterialDetection(element);
    case IElementType.aiWall:
      return validateIElementAiWall(element);
    case IElementType.floorLayout:
      return validateIElementFloorLayout(element);
    case IElementType.markup:
      return validateIElementMarkup(element);
    case IElementType.markupPolygon:
      return validateIElementMarkupPolygon(element);
    case IElementType.measurePolygon:
      return validateIElementMeasurePolygon(element);
    case IElementType.markupBim360:
      return validateIElementMarkupBim360(element);
    case IElementType.markupProcoreRfi:
      return validateIElementMarkupProcoreRfi(element);
    case IElementType.markupProcoreObservation:
      return validateIElementMarkupProcoreObservation(element);
    case IElementType.markupAccIssue:
      return validateIElementMarkupAccIssue(element);
    case IElementType.markupAccRfi:
      return validateIElementMarkupAccRfi(element);
    case IElementType.dropDownMarkupField:
      return validateIElementDropDownMarkupField(element);
    case IElementType.userDirectoryMarkupField:
      return validateIElementUserDirectoryMarkupField(element);
    case IElementType.dateTimeMarkupField:
      return validateIElementDateTimeMarkupField(element);
    case IElementType.dropDownMarkupFieldTemplate:
      return validateIElementDropDownMarkupFieldTemplate(element);
    case IElementType.userDirectoryMarkupFieldTemplate:
      return validateIElementUserDirectoryMarkupFieldTemplate(element);
    case IElementType.dateTimeMarkupFieldTemplate:
      return validateIElementDateTimeMarkupFieldTemplate(element);
    case IElementType.video360:
      return validateIElementVideo360(element);
    case IElementType.urlLink:
      return validateIElementWithUri(element);
    case IElementType.clippingBox:
      return validateIElementClippingBox(element);
  }
  // Other elements have only base properties or are currently unknown
  // so need to be validated only against the IElementBase interface
  return validateIElementBase(element);
}

/**
 * @returns that the given iElement is an image sheet.
 * @param iElement element to check
 */
function validateIElementImgSheet(
  iElement: unknown,
): iElement is IElementImgSheet {
  return (
    validateIElementWithPixelSize(iElement) &&
    validateIElementWithType(iElement, IElementType.imgSheet)
  );
}

/**
 * @returns true if the element is a IElementImgSheetTiled
 * @param iElement to check
 */
function validateIElementImgSheetTiled(
  iElement: unknown,
): iElement is IElementImgSheetTiled {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(iElement, IElementType.imgSheetTiled)
  ) {
    return false;
  }
  return (
    validatePrimitive(iElement, "pixelWidth", "number") &&
    validatePrimitive(iElement, "pixelHeight", "number") &&
    validatePrimitive(iElement, "uri", "string", PropOptional) &&
    validatePrimitive(iElement, "signedUri", "string", PropOptional) &&
    validatePrimitive(iElement, "signedUriExpiresOn", "string", PropOptional) &&
    validateArrayOf({
      object: iElement,
      prop: "levelsOfDetail",
      elementGuard: validateImgSheetLevelsOfDetail,
      optionality: PropOptional,
    })
  );
}

/**
 * @returns True if the given iElement is an IElementImg360.
 * @param iElement element to check
 */
function validateIElementImg360(iElement: unknown): iElement is IElementImg360 {
  if (
    !validateIElementWithPixelSize(iElement) ||
    !validateIElementWithType(iElement, IElementType.img360)
  ) {
    return false;
  }

  const element: Partial<IElementImg360> = iElement;

  return (
    validateNonEmptyString(element, "json1x1") &&
    validateNonEmptyString(element, "json2x1", PropOptional) &&
    validateNonEmptyString(element, "json4x2", PropOptional) &&
    validateNonEmptyString(element, "json8x4", PropOptional) &&
    (!iElement.levelsOfDetail ||
      validateArrayOf({
        object: iElement,
        prop: "levelsOfDetail",
        elementGuard: validateImg360LevelsOfDetail,
        optionality: PropOptional,
      }))
  );
}

/**
 * @returns True if the given iElement is an IElementImgCube.
 * @param iElement element to check
 */
function validateIElementImgCube(
  iElement: unknown,
): iElement is IElementImgCube {
  if (
    !validateIElementWithPixelSize(iElement) ||
    !validateIElementWithType(iElement, IElementType.imgCube)
  ) {
    return false;
  }

  const element: Partial<IElementImgCube> = iElement;

  return (
    validateNonEmptyString(element, "json6x1Low") &&
    validateNonEmptyString(element, "json6x1High")
  );
}

/**
 * @returns that the given iElement is a group.
 * @param iElement element to check
 */
function validateIElementGroup(iElement: unknown): iElement is IElementGroup {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.group) &&
    validatePrimitive(iElement, "xOr", "boolean")
  );
}

/**
 * @returns that the given iElement is project root.
 * @param iElement element to check
 */
function validateIElementProjectRoot(
  iElement: unknown,
): iElement is IElementProjectRoot {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.projectRoot) &&
    validatePrimitive(iElement, "externalId", "string") &&
    validateOfType(
      iElement,
      "metaDataMap",
      validateProjectRootMetadata,
      PropOptional,
    )
  );
}

/**
 * @returns true if the given metaDataMap is valid for a project root
 * @param metaDataMap object to check
 */
function validateProjectRootMetadata(
  metaDataMap: unknown,
): metaDataMap is IElementProjectRoot["metaDataMap"] {
  return (
    validateNotNullishObject(metaDataMap, "ProjectRootMetadata") &&
    validateOfType(
      metaDataMap,
      "projectSettings",
      validateProjectSettings,
      PropOptional,
    ) &&
    validateOfType(
      metaDataMap,
      "slideContainerMarkupSettings",
      validateSlideContainerMarkupSettings,
      PropOptional,
    )
  );
}

/**
 * @returns true if the given projectSettings is valid for a project root's metadata
 * @param projectSettings object to check
 */
function validateProjectSettings(
  projectSettings: unknown,
): projectSettings is ProjectSettings {
  return (
    validateNotNullishObject(projectSettings, "ProjectSettings") &&
    validatePrimitive(projectSettings, "unitSystem", "string", PropOptional)
  );
}

/**
 * @returns true if the given slideContainerMarkupSettings is valid for a project root's metadata
 * @param slideContainerMarkupSettings object to check
 */
function validateSlideContainerMarkupSettings(
  slideContainerMarkupSettings: unknown,
): slideContainerMarkupSettings is SlideContainerMarkupSettings {
  return (
    validateNotNullishObject(
      slideContainerMarkupSettings,
      "SlideContainerMarkupSettings",
    ) &&
    validatePrimitive(
      slideContainerMarkupSettings,
      "displayForViewers",
      "boolean",
      PropOptional,
    )
  );
}

/**
 * @returns True if the given iElement is a model3d.
 * @param iElement element to check
 */
function validateIElementModel3d(
  iElement: unknown,
): iElement is IElementModel3D {
  return (
    validateIElementWithFileUri(iElement) &&
    validateIElementWithType(iElement, IElementType.model3d)
  );
}

/**
 * @returns True if the given iElement is a IElementGenericPointCloud.
 * @param iElement element to check
 */
function validateIElementPointCloud(
  iElement: unknown,
): iElement is IElementGenericPointCloud {
  if (!validateIElementBase(iElement)) return false;

  return (
    validateIElementWithUri(iElement) &&
    VALID_POINT_CLOUD_ELEMENT_TYPES.includes(iElement.type)
  );
}

/**
 * @returns True if the given iElement is a pointCloudStreamWebShare.
 * @param iElement element to check
 */
function validateIElementPointCloudStreamWebShare(
  iElement: unknown,
): iElement is IElementPointCloudStreamWebShare {
  if (
    !validateIElementWithUri(iElement) ||
    !validateIElementWithType(iElement, IElementType.pointCloudStreamWebShare)
  ) {
    return false;
  }

  const element: Partial<IElementPointCloudStreamWebShare> = iElement;

  return (
    validateNonEmptyString(element, "webShareProjectName") &&
    validateNonEmptyString(element, "webShareEntityId") &&
    validateNonEmptyString(element, "webShareCloudId")
  );
}

/**
 * @returns True if the given iElement is a pointCloudStream.
 * @param iElement element to check
 */
function validateIElementPointCloudStream(
  iElement: unknown,
): iElement is IElementPointCloudStream {
  return (
    validateIElementWithUri(iElement) &&
    validateIElementWithType(iElement, IElementType.pointCloudStream)
  );
}

/**
 * @returns True if the given iElement is a Model3dStream.
 * @param iElement element to check
 */
function validateIElementModel3dStream(
  iElement: unknown,
): iElement is IElementModel3dStream {
  return (
    validateIElementWithUri(iElement) &&
    validateIElementWithType(iElement, IElementType.model3dStream)
  );
}

/**
 * @returns True if the given iElement is a IElementAiWall.
 * @param iElement element to check
 */
function validateIElementAiWall(iElement: unknown): iElement is IElementAiWall {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(iElement, IElementType.aiWall)
  ) {
    return false;
  }

  const element: Partial<IElementAiWall> = iElement;

  return (
    validateOfType(element, "point2", validateVec3) &&
    validateOfType(element, "point3", validateVec3) &&
    validateOfType(element, "point4", validateVec3) &&
    validateNonEmptyString(element, "sheetWallId") &&
    validateNonEmptyString(element, "sheetCornerId1") &&
    validateNonEmptyString(element, "sheetCornerId2") &&
    validateNonEmptyString(element, "sheetCornerId3") &&
    validateNonEmptyString(element, "sheetCornerId4")
  );
}

/**
 * @returns True if the given iElement is a IElementAiMaterialDetection.
 * @param iElement element to check
 */
function validateIElementAiMaterialDetection(
  iElement: unknown,
): iElement is IElementAiMaterialDetection {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(iElement, IElementType.aiMaterialDetection)
  ) {
    return false;
  }

  const element: Partial<IElementAiMaterialDetection> = iElement;

  return (
    validateNonEmptyString(element, "materialId") &&
    validatePrimitive(element, "percentage", "number")
  );
}

/**
 * @returns True if the given iElement is a IElementFloorLayout.
 * @param iElement element to check
 */
function validateIElementFloorLayout(
  iElement: unknown,
): iElement is IElementFloorLayout {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(iElement, IElementType.floorLayout)
  ) {
    return false;
  }

  const element: Partial<IElementFloorLayout> = iElement;

  return (
    validatePrimitive(element, "corners", "object") &&
    validatePrimitive(element, "walls", "object")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkup.
 * @param iElement element to check
 */
function validateIElementMarkup(iElement: unknown): iElement is IElementMarkup {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markup) &&
    validatePrimitive(iElement, "templateId", "string", PropOptional)
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupPolygon.
 * @param iElement element to check
 */
function validateIElementMarkupPolygon(
  iElement: unknown,
): iElement is IElementMarkupPolygon {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupPolygon) &&
    validateArrayOf({
      object: iElement,
      prop: "points",
      elementGuard: validatePolygonPoint,
    })
  );
}

/**
 * @returns True if the given iElement is an IElementMeasurePolygon.
 * @param iElement element to check
 */
function validateIElementMeasurePolygon(
  iElement: unknown,
): iElement is IElementMeasurePolygon {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.measurePolygon) &&
    validateArrayOf({
      object: iElement,
      prop: "points",
      elementGuard: validatePolygonPoint,
    }) &&
    validatePrimitive(iElement, "isClosed", "boolean")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkup.
 * @param iElement element to check
 */
function validateIElementMarkupBim360(
  iElement: unknown,
): iElement is IElementMarkupBim360 {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupBim360) &&
    validatePrimitive(iElement, "externalIssueId", "string")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupProcoreRfi.
 * @param iElement element to check
 */
function validateIElementMarkupProcoreRfi(
  iElement: unknown,
): iElement is IElementMarkupProcoreRfi {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupProcoreRfi) &&
    validatePrimitive(iElement, "externalIssueId", "string")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupProcoreObservation.
 * @param iElement element to check
 */
function validateIElementMarkupProcoreObservation(
  iElement: unknown,
): iElement is IElementMarkupProcoreObservation {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupProcoreObservation) &&
    validatePrimitive(iElement, "externalIssueId", "string")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupAccIssue.
 * @param iElement element to check
 */
function validateIElementMarkupAccIssue(
  iElement: unknown,
): iElement is IElementMarkupAccIssue {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupAccIssue) &&
    validatePrimitive(iElement, "externalIssueId", "string")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupAccRfi.
 * @param iElement element to check
 */
function validateIElementMarkupAccRfi(
  iElement: unknown,
): iElement is IElementMarkupAccRfi {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.markupAccRfi) &&
    validatePrimitive(iElement, "externalIssueId", "string")
  );
}

/**
 * @returns True if the given iElement is an IElementMarkupProcoreObservation.
 * @param iElement element to check
 */
function validateIElementDropDownMarkupField(
  iElement: unknown,
): iElement is IElementDropDownMarkupField {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.dropDownMarkupField) &&
    validatePrimitive(iElement, "templateId", "string", PropOptional) &&
    validatePrimitive(iElement, "value", "string", PropOptional)
  );
}

/**
 * @returns True if the given iElement is an IElementUserDirectoryMarkupField.
 * @param iElement element to check
 */
function validateIElementUserDirectoryMarkupField(
  iElement: unknown,
): iElement is IElementUserDirectoryMarkupField {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.userDirectoryMarkupField) &&
    validatePrimitive(iElement, "templateId", "string", PropOptional) &&
    validateArrayOf({
      object: iElement,
      prop: "values",
      elementGuard: (x) => typeof x === "string",
      optionality: PropOptional,
    })
  );
}

/**
 * @returns True if the given iElement is an IElementDateTimeMarkupField.
 * @param iElement element to check
 */
function validateIElementDateTimeMarkupField(
  iElement: unknown,
): iElement is IElementDateTimeMarkupField {
  return (
    validateIElementBase(iElement) &&
    validateIElementWithType(iElement, IElementType.dateTimeMarkupField) &&
    validatePrimitive(iElement, "templateId", "string", PropOptional) &&
    validatePrimitive(iElement, "value", "string", PropOptional)
  );
}

/**
 * @returns True if the given iElement is a IElementDropDownMarkupFieldTemplate.
 * @param iElement element to check
 */
function validateIElementDropDownMarkupFieldTemplate(
  iElement: unknown,
): iElement is IElementDropDownMarkupFieldTemplate {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(
      iElement,
      IElementType.dropDownMarkupFieldTemplate,
    )
  ) {
    return false;
  }

  const element: Partial<IElementDropDownMarkupFieldTemplate> = iElement;

  return (
    validatePrimitive(element, "mandatory", "boolean") &&
    validateArrayOf({
      object: element,
      prop: "values",
      elementGuard: (x) => typeof x === "string",
    })
  );
}

/**
 * @returns True if the given iElement is a IElementUserDirectoryMarkupFieldTemplate.
 * @param iElement element to check
 */
function validateIElementUserDirectoryMarkupFieldTemplate(
  iElement: unknown,
): iElement is IElementUserDirectoryMarkupFieldTemplate {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(
      iElement,
      IElementType.userDirectoryMarkupFieldTemplate,
    )
  ) {
    return false;
  }

  const element: Partial<IElementUserDirectoryMarkupFieldTemplate> = iElement;

  return validatePrimitive(element, "mandatory", "boolean");
}

/**
 * @returns True if the given iElement is a IElementDateTimeMarkupFieldTemplate.
 * @param iElement element to check
 */
function validateIElementDateTimeMarkupFieldTemplate(
  iElement: unknown,
): iElement is IElementDateTimeMarkupFieldTemplate {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(
      iElement,
      IElementType.dateTimeMarkupFieldTemplate,
    )
  ) {
    return false;
  }

  const element: Partial<IElementDateTimeMarkupFieldTemplate> = iElement;

  return validatePrimitive(element, "mandatory", "boolean");
}

/**
 * @returns true if the input element is a Video360
 * @param iElement The element to check
 */
function validateIElementVideo360(
  iElement: unknown,
): iElement is IElementVideo360 {
  return (
    validateIElementWithPixelSize(iElement) &&
    validateIElementWithType(iElement, IElementType.video360) &&
    validateIElementWithPixelSize(iElement) &&
    validatePrimitive(iElement, "durationInMilliseconds", "number")
  );
}

/**
 * @returns true if the input element is a clipping box
 * @param iElement The element to check
 */
function validateIElementClippingBox(
  iElement: unknown,
): iElement is IElementClippingBox {
  if (
    !validateIElementBase(iElement) ||
    !validateIElementWithType(iElement, IElementType.clippingBox)
  ) {
    return false;
  }

  const element: Partial<IElementClippingBox> = iElement;

  return validateOfType(element, "size", validateVec3);
}
