import { BufferGeometry, Curve, Float32BufferAttribute, Vector3 } from "three";

/** Default length to segments factor for a PathGeometry */
const DEFAULT_SEGMENTS_PER_METER = 50;

/** Default width in meters for a PathGeometry*/
const DEFAULT_PATH_WIDTH = 0.5;

/** The maximum number of segments for a path */
const MAX_NUMBER_OF_SEGMENTS = 10000;

/** Extra options to customize a PathGeometry */
export type PathGeometryOptions = {
	/**
	 * Parameter used to compute how many straight segments will be used to render the curve.
	 * The higher this parameter, the smoother the render.
	 *
	 * @default 50
	 */
	segmentsPerMeter?: number;

	/** Width of the path */
	width?: number;
};

/**
 * A specialized BufferGeometry used to render paths
 *
 * Starting from a curve it generated a geometry suitable to render a mesh that creates a path following that curve
 */
export class PathGeometry extends BufferGeometry {
	/** Curve this path is following */
	#curve: Curve<Vector3>;

	/**
	 * Parameter used to compute how many straight segments will be used to render the curve.
	 * The higher this parameter, the smoother the render.
	 *
	 * @default 50
	 */
	#segmentsPerMeter: number;

	/** Width of the path */
	#width: number;

	/**
	 * Create a new geometry that can be used to render a curve as a Path mesh
	 *
	 * @param curve the curve this path need to follow
	 * @param options to customize the curve
	 */
	constructor(curve: Curve<Vector3>, options: PathGeometryOptions = {}) {
		super();
		this.#curve = curve;
		this.#segmentsPerMeter = options.segmentsPerMeter ?? DEFAULT_SEGMENTS_PER_METER;
		this.#width = options.width ?? DEFAULT_PATH_WIDTH;
		this.updateGeometry();
	}

	/** @returns the curve this path is following */
	get curve(): Curve<Vector3> {
		return this.#curve;
	}

	/** Change the curve this path should follow */
	set curve(curve: Curve<Vector3>) {
		this.#curve = curve;
		this.updateGeometry();
	}

	/** @returns the number of curve segments for each meter*/
	get segmentsPerMeter(): number {
		return this.#segmentsPerMeter;
	}

	/**
	 * Parameter used to compute how many straight segments will be used to render the curve.
	 * The higher this parameter, the smoother the render.
	 */
	set segmentsPerMeter(factor: number) {
		this.#segmentsPerMeter = factor;
		this.updateGeometry();
	}

	/** @returns the current width of the path in meters */
	get width(): number {
		return this.#width;
	}

	/** Set the width of the path in meters */
	set width(width: number) {
		this.#width = width;
		this.updateGeometry();
	}

	/** Update the geometry attributes to match the class properties  */
	private updateGeometry(): void {
		if (this.#curve.getLength() === 0) {
			return;
		}

		// Compute the number of segments to get a smooth curve
		const curveLength = this.#curve.getLength();
		const segments = Math.min(Math.floor(curveLength * this.#segmentsPerMeter), MAX_NUMBER_OF_SEGMENTS);
		const segmentsPerMeter = segments / curveLength;
		const closed = this.#curve.getPoint(0).distanceTo(this.#curve.getPoint(1)) === 0;

		// Cache vectors to not reallocate them for every point in the curve
		const point = new Vector3();
		const leftPoint = new Vector3();
		const tangent = new Vector3();
		const cross = new Vector3();
		const side = new Vector3(1, 0, 0);
		const Y_AXIS = new Vector3(0, 1, 0);
		const EPSILON = 1e-6;

		// Array used to build the attributes for the geometry
		const vertices: number[] = [];
		const indices: number[] = [];
		const uv: number[] = [];

		for (let segment = 0; segment <= segments; ++segment) {
			const perc = segment / segments;
			this.#curve.getPointAt(perc, point);
			this.#curve.getTangentAt(perc, tangent);
			cross.crossVectors(Y_AXIS, tangent);
			const crossLength = cross.length();

			// If the tangent is not collinear to the Y_AXIS compute the new side vector
			// if it's collinear reuse the side computed previously
			// it would be better to compute the curve normal but this is not possible
			// with ThreeJS Curves
			if (crossLength > EPSILON) {
				side.copy(cross).multiplyScalar(this.#width / (2 * crossLength));
			}

			// Every meter the texture is repeated, in this way
			// the path is covered with nice pointing arrows each
			// meter.
			const v = segment / segmentsPerMeter;

			// Compute the left point of the path
			vertices.push(...leftPoint.copy(point).add(side).toArray());
			uv.push(0, v);

			// Compute the right point of the path
			vertices.push(...point.sub(side).toArray());
			uv.push(1, v);

			// Compute the indices to link the left and right points to create the two triangles
			// needed to define the path mesh
			if (segment < segments - 1) {
				const index = segment * 2;
				indices.push(index, index + 1, index + 2, index + 2, index + 1, index + 3);
			} else if (closed) {
				const index = segment * 2;
				indices.push(index, index + 1, 0, 0, index + 1, 1);
			}
		}
		this.setIndex(indices);
		this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
		this.setAttribute("uv", new Float32BufferAttribute(uv, 2));
	}
}
