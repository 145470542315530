import {
  GUID,
  ILabel,
  IPose,
  ISOTimeString,
  MetaData,
  URI,
  UserId,
} from "./properties";

/**
 * Enum of iElement types (not complete)
 */
export enum IElementType {
  projectRoot = "ProjectRoot",
  group = "Group",
  section = "Section",
  timeSeries = "TimeSeries",
  imgSheet = "ImgSheet",
  imgSheetTiled = "ImgSheetTiled",
  img360 = "Img360",
  imgCube = "ImgCube",
  img2d = "Img2d",
  depthMap = "DepthMap",
  model3d = "Model3d",
  iElemLink = "IElemLink",
  pointCloudCpe = "PointCloudCpe",
  pointCloudE57 = "PointCloudE57",
  pointCloudElsRaw = "PointCloudElsRaw",
  pointCloudGeoSlam = "PointCloudGeoSlam",
  pointCloudLaz = "PointCloudLaz",
  pointCloudStreamWebShare = "PointCloudStreamWebShare",
  pointCloudStream = "PointCloudStream",
  model3dStream = "Model3dStream",
  urlLink = "UrlLink",
  markup = "Markup",
  audioAttachment = "AudioAttachment",
  attachment = "Attachment",
  measureWall = "MeasureWall",
  measureLine = "MeasureLine",
  pCloud = "PCloud",

  aiWall = "AiWall",
  aiMaterialDetection = "AiMaterialDetection",
  dropDownMarkupFieldTemplate = "DropDownMarkupFieldTemplate",
  dropDownMarkupField = "DropDownMarkupField",
  userDirectoryMarkupFieldTemplate = "UserDirectoryMarkupFieldTemplate",
  userDirectoryMarkupField = "UserDirectoryMarkupField",
  roomLayout = "RoomLayout",
  markupTemplate = "MarkupTemplate",
  dateTimeMarkupFieldTemplate = "DateTimeMarkupFieldTemplate",
  dateTimeMarkupField = "DateTimeMarkupField",
  floorLayout = "FloorLayout",
  clippingBox = "ClippingBox",

  // Measurements and Annoatations
  markupPolygon = "MarkupPolygon",
  measurePolygon = "MeasurePolygon",

  // NOTE: The types below do not have a dedicated interface yet
  pdfAttachment = "PdfAttachment",
  camView = "CamView",
  markupBim360 = "MarkupBim360",
  markupProcoreObservation = "MarkupProcoreObservation",
  markupProcoreRfi = "MarkupProcoreRfi",
  markupAccIssue = "MarkupAccIssue",
  markupAccRfi = "MarkupAccRfi",

  // Added to support video mode custom project
  video360 = "Video360",
  video360Raw = "Video360Raw",
  video2d = "Video2d",
  photocoreNetwork = "PhotocoreNetwork",
  pointCloud = "PointCloud",
}

/** All possible values for the typeHint field */
export enum IElementTypeHint {
  slideContainer = "SlideContainer",
  area = "Area",
  rooms = "Rooms",
  room = "Room",
  timeTravel = "TimeTravel",
  attachments = "Attachments",
  nodes = "Nodes",
  node = "Node",
  command = "Command",
  markup = "Markup",
  hotSpotLink = "HotSpotLink",
  arMarker2D = "ArMarker2D",
  advancedMarkup = "AdvancedMarkup",
  markupAssigneeId = "MarkupAssigneeId",
  markupIssueStatus = "MarkupIssueStatus",
  markupIssueDueDate = "MarkupIssueDueDate",
  markupIssueImage = "MarkupIssueImage",
  markups = "Markups",
  videoRecordings = "VideoRecordings",
  videoRecordingTrack = "VideoRecordingTrack",
  roomPathLinks = "RoomPathLinks",
  roomPathLink = "RoomPathLink",
  dollhouse = "Dollhouse",
  cadModel = "CADModel",
  dataSession = "DataSession",
  dataSetPCloudUpload = "DataSetPCloudUpload",
  dataSetGeoSlam = "DataSetGeoSlam",
  dataSetVideoWalk = "DataSetVideoWalk",
  dataSetPhotogrammetry = "DataSetPhotogrammetry",
  photogrammetryPhotos = "PhotogrammetryPhotos",
  photogrammetryVideos = "PhotogrammetryVideos",
  dataSetWs = "DataSetWs",
  odometryPath = "OdometryPath",
  poiLowRes = "PoiLowRes",
  poiHighRes = "PoiHighRes",
  poiStandard = "PoiStandard",
  poi = "Poi",
  bimModel = "BimModel",
  bimImport = "BimImport",
  cluster = "Cluster",
  layoutPlan = "LayoutPlan",
  flash = "Flash",
  grayScale = "GrayScale",
  spaceAnnotation = "SpaceAnnotation",
  mapAnnotation = "MapAnnotation",
  spaceMeasurement = "SpaceMeasurement",
  mapMeasurement = "MapMeasurement",
  multiScanMeasurement = "MultiScanMeasurement",
  clippingBox = "ClippingBox",
  captureTree = "CaptureTree",
  dataSets = "DataSets",
  scene = "Scene",
  focusScan = "FocusScan",
  elsScan = "ElsScan",
  structuredE57 = "StructuredE57",
  dataSetFocus = "DataSetFocus",
  overviewMap = "OverviewMap",
  dataSetEls = "DataSetEls",
  volume = "Volume",
  layer = "Layer",
}

/** Common properties of every iElement. */
export interface IElementBase {
  /** The unique global identifier of the iElement. */
  id: GUID;

  /**
   *  Non-unique hierarchy ID
   *  https://docs.microsoft.com/en-us/sql/t-sql/data-types/hierarchyid-data-type-method-reference
   */
  // TODO: Not part of swagger docs, check with project API team
  orderIndicator?: number;

  /** Reference to the project root IElement. */
  rootId: GUID;

  /**
   * Reference to the project root IElement.
   *
   * @deprecated Use `rootId` instead.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  root_Id: GUID;

  /**
   * The IDs of the child elements.
   *
   * This creates the tree structure of the project.
   *
   * - Emtpy list: no children
   * - null: children not resolved
   */
  childrenIds?: GUID[] | null;

  /**
   * The IDs of the child elements.
   *
   * This creates the tree structure of the project.
   *
   * - Emtpy list: no children
   * - null: children not resolved
   *
   * @deprecated Use `childrenIds` instead.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  children_Ids?: GUID[] | null;

  /** Set by the database, based on class name. */
  type: IElementType | string;

  /**
   * Optional type hint in the old data model, set after conversion to a
   * number representing e.g. SlideContainer, Floorplan, Slide, TimeTravel, Node...
   */
  typeHint?: IElementTypeHint | string | null;

  /** A human readable name, e.g. "Room 1". */
  name: string;

  /** An optional user entered description. */
  descr?: string | null;

  /**
   * The ID of the parent iElement.
   *
   * If this is null, it's a root element.
   */
  parentId?: GUID | null;

  /**
   * The ID of the parent iElement.
   *
   * If this is null, it's a root element.
   *
   * @deprecated Use `parentId` instead.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  parent_Id?: GUID | null;

  /** The ID of the user who created the element. */
  createdBy: UserId;

  /** The date time when the element was created.  */
  createdAt: ISOTimeString;

  /** The ID of the user who last modified the element. */
  modifiedBy: UserId;

  /** The date time when the element was last modified. */
  modifiedAt: ISOTimeString;

  /** The URI to a thumbnail image of the element. */
  thumbnailUri?: URI | null;

  /** Positional data for the element. */
  pose?: IPose | null;

  /**
   * Meta data should never contain information the backend has to rely on
   * (e.g. IDs to 3rd parties should not be stored in there)
   */
  metaDataMap?: Record<string, unknown> | null;

  /**
   * Contains the same information as metaDataMap, but as JSON string
   *
   * @deprecated Use metaDataMap instead
   */
  metaData?: MetaData | null;

  /**
   * The UTC time when the iele was last synced/changed to the DB.
   *
   * If null then the ielem was never stored to the DB yet.
   */
  lastModifiedInDb?: ISOTimeString | null;

  /** The labels of the element. */
  labels?: ILabel[] | null;
}

/**
 * Compare two IElements by their id
 *
 * @param a first element
 * @param b second element
 * @returns true if they have the same id
 */
export function compareById(a: IElementBase, b: IElementBase): boolean {
  return a.id === b.id;
}

/**
 * Utility type to restrict the type hint of `E` to `H`.
 */
export type WithHint<E extends IElementBase, H extends IElementTypeHint> = E & {
  typeHint: H;
};
