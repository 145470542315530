import { PropsWithChildren } from "react";

export type TranslateVarProps = {
  /**
   * The name of this variable to help translators in the LocalizeJS dashboard
   *
   * @see https://developers.localizejs.com/docs/using-html-syntax-to-prepare-phrases#variables
   */
  name: string;

  /**
   * The value to use to know how the phrase should be pluralized if singular and plural versions are required
   *
   * @see https://developers.localizejs.com/docs/using-html-syntax-to-prepare-phrases#attribute-isolate
   */
  pluralize?: number;

  /**
   * Set to true to mark the content as an isolated translation context and disable LocalizeJS grouping
   *
   * @see https://developers.localizejs.com/docs/using-html-syntax-to-prepare-phrases#attribute-isolate
   */
  isolate?: boolean;
};

/**
 * @returns a HTMLVarElement with extra properties used to control phrases translations
 *
 * Note: This components uses the LocalizeJS data prefixes because passing custom property in react does not work as expected
 * @see https://dev.azure.com/faro-connect/ViewerApp/_git/LotvMonorepo?path=/libs/project-source/src/hooks/use-signed-url.ts&version=GBmaster&_a=contents
 */
export function TranslateVar({
  children,
  name,
  pluralize,
  isolate,
}: PropsWithChildren<TranslateVarProps>): JSX.Element {
  return (
    <var data-var={name} data-pluralize={pluralize} data-isolate={isolate}>
      {children}
    </var>
  );
}
