import { Disposable, TypedEvent, assert } from "@faro-lotv/foundation";
import {
	Box3,
	Camera,
	Frustum,
	Intersection,
	Material,
	Matrix4,
	Object3D,
	Ray,
	Raycaster,
	Vector2,
	Vector3,
} from "three";
import { FrustumBoxCheck, frustumIntersectsBox } from "../Algorithms/FrustumIntersection";
import { AdaptivePointsMaterial } from "../Materials/AdaptivePointsMaterial";
import { PointCloud, PointCloudRaycastingOptions } from "../PointCloud/PointCloud";
import { PointCloudBufferGeometry } from "../PointCloud/PointCloudBufferGeometry";
import { PotreeTree } from "../Potree/PotreeTree";
import { LodCachingStrategy } from "./LodCachingStrategy";
import { LodGroup } from "./LodGroup";
import { NodeCacheElement } from "./LodMultiview";
import { LodTree, LodTreeNode, NodeState } from "./LodTree";
import { FetcherClient, LodTreeFetcher } from "./LodTreeFetcher";
import { VisibleNodesStrategy, WeightedNode } from "./VisibleNodeStrategy";

/**
 * Internal type used to implement subsampled rendering functionality.
 */
type SubsampledRenderingOptions = {
	/** Whether a camera renders with subsampled rendering */
	enabled: boolean;
	/** How much of the camera visible nodes should be rendered by the subsampled camera */
	fraction: number;
	/** Maximum count of the camera visible nodes should be rendered by the subsampled camera */
	maxNodes: number;
	/** Min weight (screen occupancy) that a node should have to be rendered during subsampled rendering */
	minWeight: number;
};

/**
 * Compute the estimated point density for a LodTree node in meters
 *
 * @param tree the LodTree containing the node
 * @param node the node we want to compute the point density
 * @returns the estimated point density of the node in meters
 */
function computeLodNodePointDensity(tree: LodTree, node: LodTreeNode): number {
	// TODO: https://faro01.atlassian.net/browse/SWEB-1719 Update LodTree to make pointDensity represent the same value for all trees
	if (tree instanceof PotreeTree) {
		const POTREE_DENSITY_OFFSET = 1.5;
		const POTREE_SPACING_FACTOR = 1.7;
		const lodOffset = Math.log2(node.pointDensity) / 2 - POTREE_DENSITY_OFFSET;
		const nodeDepth = node.depth + lodOffset;
		const rootSpacing = tree.spacing;
		return (POTREE_SPACING_FACTOR * rootSpacing) / Math.pow(2, nodeDepth);
	}
	// WEBSHARE
	return node.pointDensity / 1000;
}

/**
 * Custom raycasting options for an LodPointCloud
 */
export type LodPointCloudRaycastingOptions = PointCloudRaycastingOptions & {
	/** Max depth of the LodTree to use for raycasting */
	maxDepth: number;

	/** True to return only the closest point to the camera from the raycasting */
	shouldReturnOnlyClosest: boolean;

	/**
	 * The maximum number of new picking trees that can be created for each pick operation.
	 * The total number picking trees remains unchanged
	 */
	maxPickingTreesPerRaycast: number;

	/** Max time (milliseconds) that can be spent in a single raycast invocation when doing real time raycasting */
	realtimeBudget: number;
};

export type LodPointCloudOptions = {
	/**
	 * Policy that determines whether nodes that fall out
	 * of visibility are kept in cache and with which criterion
	 * (store max N nodes in cache, store nodes frequently accessed, etc.)
	 */
	lodCachingStrategy: LodCachingStrategy;

	/** Custom raycasting options for this LodPointCloud */
	raycasting: LodPointCloudRaycastingOptions;
};

/**
 * Default raycast settings for lod point cloud, geared toward low memory usage and fast, not super precise, raycasting
 */
export const LOD_POINT_CLOUD_RAYCASTING_DEFAULTS: Required<LodPointCloudRaycastingOptions> = {
	enabled: true,
	threshold: 0.05,
	pickingTree: {
		enabled: false,
		autoUpdate: true,
		maxDepth: 6,
	},
	maxDepth: 4,
	shouldReturnOnlyClosest: false,
	maxPickingTreesPerRaycast: Number.MAX_SAFE_INTEGER,
	realtimeBudget: 1,
};

/**
 * Default options for an LodPointCloud
 */
export const LOD_POINT_CLOUD_DEFAULTS: LodPointCloudOptions = {
	lodCachingStrategy: new LodCachingStrategy(),
	raycasting: LOD_POINT_CLOUD_RAYCASTING_DEFAULTS,
};

/**
 * An object that keeps a chuck of points in GPU memory, providing functions
 * to interact with the layers for multiview logic.
 */
export class PointsCacheElement implements NodeCacheElement {
	/** The points buffer together with its layer */
	points: PointCloud;
	/** The point count */
	pointCount: number;
	/** Last rendered time stored for caching purposes */
	lastRenderedTime: number;

	/**
	 * @param points The point cloud
	 * @param pointCount The point count
	 * @param lastTime Timestamp of last time this element was visible.
	 */
	constructor(points: PointCloud, pointCount: number, lastTime: number) {
		this.points = points;
		this.pointCount = pointCount;
		this.lastRenderedTime = lastTime;
	}

	/** @returns the points as an object3D for management by LodMultiview. */
	get object(): Object3D {
		return this.points;
	}
}

/**
 * A class capable of rendering lod point clouds.
 */
export class LodPointCloud extends LodGroup implements FetcherClient {
	/**
	 * The material used to render the point cloud chunks
	 */
	#material: Material;
	/**
	 * The lodTreeFetcher that fetches nodes
	 */
	#lodFetcher: LodTreeFetcher;
	/**
	 * The list of nodes that are cached in GPU memory, but not added to the group for rendering.
	 */
	#nodesInMemory = new Map<number, PointsCacheElement>();
	/**
	 * The list of nodes whose points are currently loaded in GPU and rendered, implemented as a map node idx -> Points object.
	 */
	#nodesInGPU = new Map<number, PointsCacheElement>();
	/**
	 * The total number of points that are currently being rendered.
	 */
	#totPointsInGPU = 0;
	/**
	 * LOD cloud options
	 */
	#options: LodPointCloudOptions;
	/**
	 * A functor used on disposal, to deregister this point cloud
	 * from the 'nodeReady' event of the fetcher.
	 */
	#fetcherEvDetacher: Disposable | null;

	/** The custom raycasting options for this LodPointCloud */
	raycasting: Required<LodPointCloudRaycastingOptions>;

	/** Set to true to allow the raycasting to return imprecise results but to guarantee realtime performances */
	realTimeRaycasting = true;

	/** Cached inverse world matrix to not re-allocate at each raycast */
	#matrixWorldInv = new Matrix4();

	/** Cached local space ray to not re-allocate at each raycast */
	#localRay = new Ray();

	/** Cached clipping box expressed as a frustum in local space of the point cloud. */
	#clipFrustumLocal = new Frustum();

	/** Cached clipping box expressed as a frustum in world space. */
	#clipFrustumWorld = new Frustum();

	/** Cached raycast target to not re-allocate at each raycast */
	#raycastTarget = new Vector3();

	/** The rendering options used in case of sub-sampled rendering */
	#subsampledRendering: SubsampledRenderingOptions = { enabled: false, fraction: 1.0, maxNodes: 100, minWeight: 0.0 };

	/** State variable to remember whether all points have been received or this cloud is still waiting for some. */
	#allPointsReceived = false;

	/** Buffer to store raycast `Intersections` at each call, used to avoid creating the object repeatedly */
	#localIntersects = new Array<Intersection>();

	/** Buffer to store raycast results that may also fall outside the clipping box */
	#preClippingIntersects = new Array<Intersection>();

	/** Signal emitted whenever a new node, that is visible yet not received, is returned by the fetcher. */
	nodeReady = new TypedEvent<number>();

	/** Signal emitted when all nodes visible from this view have been received and are being rendered. */
	allPointsReceived = new TypedEvent<void>();

	/** The bounding box of the points of the point cloud (in local space). */
	readonly boundingBox: Box3;

	/** @returns true if this PointCloud is mono chromatic */
	get monochrome(): boolean {
		// Can be checked only for Potree point clouds
		if (!(this.tree instanceof PotreeTree)) return false;

		return this.tree.monochrome;
	}

	/**
	 * @returns the current Cache Clean Computer
	 */
	get cacheCleanComputer(): LodCachingStrategy {
		return this.#options.lodCachingStrategy;
	}
	/** @param computer the new Cache Clean Computer*/
	set cacheCleanComputer(computer: LodCachingStrategy) {
		this.#options.lodCachingStrategy = computer;
	}

	/** The object responsible for computing the visible LOD nodes given a tree, a camera, and a screen resolution. */
	visibleNodesStrategy: VisibleNodesStrategy;

	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param tree The lod tree data structure to render, will create and own a LodTreeFetcher internally.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(tree: LodTree, material: Material, options?: Partial<LodPointCloudOptions>);
	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param fetcher The LodTreeFetcher used to get the points, will not own the fetcher and will not dispose it.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(fetcher: LodTreeFetcher, material: Material, options?: Partial<LodPointCloudOptions>);
	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param treeOrFetcher The lod tree data structure.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(treeOrFetcher: LodTree | LodTreeFetcher, material: Material, options?: Partial<LodPointCloudOptions>) {
		super();
		if (treeOrFetcher instanceof LodTreeFetcher) {
			this.#lodFetcher = treeOrFetcher;
		} else {
			this.#lodFetcher = new LodTreeFetcher(treeOrFetcher);
		}
		this.#material = material;
		this.#options = { ...LOD_POINT_CLOUD_DEFAULTS, ...options };
		this.raycasting = { ...LOD_POINT_CLOUD_RAYCASTING_DEFAULTS, ...this.#options.raycasting };

		// The visible nodes strategy should be per cloud and not per tree, since
		// multiple views per tree are allowed
		this.visibleNodesStrategy = this.tree.visibleNodesStrategy.clone();

		this.position.setFromMatrixPosition(this.lodTreeFetcher.tree.worldMatrix);
		this.quaternion.setFromRotationMatrix(this.lodTreeFetcher.tree.worldMatrix);
		this.matrixWorldNeedsUpdate = true;

		// Listen to event of points being downloaded
		this.#fetcherEvDetacher = this.lodTreeFetcher.nodeReady.on((event) => {
			this.#handlePointsReceived(event.nodeIdx, event.points);
		});

		// The tree bounding box includes the tree offset that is copied into this LodPointCloud's transform
		this.boundingBox = this.tree.boundingBox.clone().applyMatrix4(this.tree.worldMatrixInverse);
	}

	/** @returns a new LodPointCloud referencing but not owning the same point source of this point cloud */
	createView(): LodPointCloud {
		const newView = new LodPointCloud(this.#lodFetcher, this.material.clone(), {
			lodCachingStrategy: this.cacheCleanComputer,
			raycasting: { ...this.raycasting },
		});
		newView.visibleNodesStrategy = this.visibleNodesStrategy.clone();
		return newView;
	}

	/**
	 * If the tree has a root node with points then pre-load it so when we show the
	 * pointcloud we already have an overview.
	 *
	 * If the tree does not have a proper root node, so the first valid level is
	 * already on multiple children skip the pre-loading for now
	 */
	preloadRootNode(): void {
		// Find the first node in the tree with points
		let firstNodeWithPoints = this.tree.getNode(this.tree.root.id);
		while (firstNodeWithPoints.numPoints === 0 && firstNodeWithPoints.children.length === 1) {
			firstNodeWithPoints = firstNodeWithPoints.children[0];
		}

		// this tree does not have a proper root node so we skip pre-loading
		if (firstNodeWithPoints.numPoints === 0) return;

		this.lodTreeFetcher.requestNodes([{ id: firstNodeWithPoints.id, weight: Number.POSITIVE_INFINITY }], this);
	}

	/**
	 * Dispose all resources for this object
	 */
	dispose(): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		this.#material?.dispose();
		this.removeAllNodes();
		// We need to tell to the fetcher that this object
		// does not need any nodes anymore, otherwise the fetcher will keep
		// references to this object and keep it alive even when it should be
		// deallocated.
		while (this.#nodesInMemory.size > 0) {
			const [n] = this.#nodesInMemory.keys();
			// Removing points of node 'n' from GPU and from RAM.
			const node = this.#nodesInMemory.get(n);
			node?.points.dispose(false);
			this.#nodesInMemory.delete(n);
		}

		// Remove this instance from the fetcher
		this.#lodFetcher.removeClient(this);

		// Stop listening to the fetcher.
		this.#fetcherEvDetacher?.dispose();
		this.#fetcherEvDetacher = null;

		// Dispose the fetcher itself if we're owning it
		this.#lodFetcher.disposeIfNoClients();
	}

	/** @inheritdoc */
	protected computeVisibleNodes(camera: Camera, screenSize: Vector2): WeightedNode[] {
		const ret = this.visibleNodesStrategy.compute(this.tree, this.matrixWorld, camera, screenSize);
		if (ret.length === 0) return ret;
		if (this.#subsampledRendering.enabled) {
			const maxNodes = Math.min(
				this.#subsampledRendering.maxNodes,
				Math.floor(ret.length * this.#subsampledRendering.fraction),
				ret.length - 1,
			);
			this.#subsampledRendering.minWeight = ret[maxNodes].weight;
		} else {
			this.#subsampledRendering.minWeight = 0.0;
		}
		return ret;
	}

	/** @inheritdoc */
	protected requestNodes(nodes: WeightedNode[]): void {
		// Ask the fetcher to fetch the nodes to load. When the download of each node is finished, handlePointsReceived() will be called
		this.lodTreeFetcher.requestNodes(nodes, this);
	}

	/** @inheritdoc */
	protected cacheOrDisposeNodes(unusedNodes: WeightedNode[]): void {
		const nodesToDelete = this.#options.lodCachingStrategy.computeDisposableNodes(
			this.#nodesInMemory,
			this.#nodesInGPU,
		);
		for (const n of nodesToDelete) {
			// Removing points of node 'n' from GPU and from RAM.
			const node = this.#nodesInMemory.get(n);
			node?.points.dispose(false);
			this.#nodesInMemory.delete(n);
			// Tell the fetcher that this object does not need this node anymore.
			this.lodTreeFetcher.releaseNode(n, this);
			// After many tests, we have validated that, if this is the only client of the fetcher,
			// then node 'n' is guaranteed to be 'NotInUse' after the 'releaseNode' call.
		}
		// Iterate through the unusedNodes. The ones that should remain in the cache have now
		// the 'InUse' state. So we cancel the download of the remaining ones.
		for (const n of unusedNodes) {
			const treeNode = this.tree.getNode(n.id);
			if (treeNode.state === NodeState.Downloading || treeNode.state === NodeState.WaitingForDownload) {
				this.lodTreeFetcher.releaseNode(n.id, this);
			}
		}
	}

	/** @inheritdoc */
	protected getNodeInGPU(nodeIdx: number): NodeCacheElement | undefined {
		return this.#nodesInGPU.get(nodeIdx);
	}

	/** @inheritdoc */
	protected getNodeInMemory(nodeIdx: number): NodeCacheElement | undefined {
		return this.#nodesInMemory.get(nodeIdx);
	}

	/** @inheritdoc */
	protected setNodeVisible(node: number, nodeData: NodeCacheElement): void {
		// By design we are sure that in the line below 'nodeData' can be downcasted to PointsCacheElement,
		// because we construct it as such.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const pointsData = nodeData as PointsCacheElement;
		this.add(pointsData.points);
		this.#nodesInGPU.set(node, pointsData);
		this.#totPointsInGPU += pointsData.pointCount;
	}

	/** @inheritdoc */
	protected setNodeNotVisible(node: number, nodeData: NodeCacheElement): void {
		// By design we are sure that in the line below 'nodeData' can be downcasted to PointsCacheElement,
		// because we construct it as such.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const pointsData = nodeData as PointsCacheElement;
		this.remove(pointsData.points);
		this.#nodesInGPU.delete(node);
		this.#totPointsInGPU -= pointsData.pointCount;
	}

	/** @inheritdoc */
	override updateCamera(camera: Camera, screenSize: Vector2): void {
		super.updateCamera(camera, screenSize);
		this.#checkSubsampledRenderingVisibility();
	}

	/**
	 * Computes which of the visible nodes should
	 * be rendered according to subsampled rendering
	 *
	 */
	#checkSubsampledRenderingVisibility(): void {
		for (const node of this.currentVisibleNodes) {
			const gpuNode = this.#nodesInGPU.get(node.id);
			if (gpuNode) {
				gpuNode.points.visible = node.weight >= this.#subsampledRendering.minWeight;
			}
		}
	}

	/**
	 * Creates a threeJS PointCloud object that encapsulates the given 'points' VBO
	 * for the given node of the LOD structure.
	 *
	 * @param nodeIdx ID of the LOD node
	 * @param points points VBO to be stored in the PointCloud object
	 * @returns the PointCloud object with correct raycasting, updating and bounding box settings.
	 */
	#createPointCloudNode(nodeIdx: number, points: PointCloudBufferGeometry): PointCloud {
		const node = this.tree.getNode(nodeIdx);
		const p = new PointCloud(points, this.#material, { autoUpdateBoundingSphere: false });
		p.geometry.options.color.setHSL(node.depth / (this.tree.maxDepth - 1), 1, 0.5);
		// Disable pc picking by default as we provide a customized picking for LOD Clouds
		p.raycasting.enabled = false;
		const onBeforeRender = p.onBeforeRender.bind(p);
		p.onBeforeRender = (renderer, scene, camera, geometry, material) => {
			onBeforeRender(renderer, scene, camera, geometry, material);
			if (material instanceof AdaptivePointsMaterial) {
				material.updateNode(nodeIdx, node.depth);
			}
		};
		p.geometry.boundingBox = node.boundingBox;
		p.geometry.computeBoundingSphere();
		return p;
	}

	/** Checks whether the 'allPointsReceived' signal should be emitted. */
	#checkAllPointsReceived(): void {
		const allPointsReceived = this.#nodesInGPU.size === this.currentVisibleNodes.length;
		if (allPointsReceived && !this.#allPointsReceived) this.allPointsReceived.emit();
		this.#allPointsReceived = allPointsReceived;
	}

	/**
	 * Just after download, loads a node's points to GPU.
	 *
	 * @param nodeIdx Idx of node that is being loaded to GPU
	 * @param points Point data to be loaded
	 */
	#handlePointsReceived(nodeIdx: number, points: PointCloudBufferGeometry): void {
		const visible = this.getNodeVisibility(nodeIdx);
		if (visible) {
			// In a splitscreen scenario, it is possible that another view has requested
			// 'nodeIdx' while this view has already 'nodeIdx' in GPU or in cache.
			// Therefore, if this view already has the data for 'nodeIdx', there is nothing
			// to do and the function returns.
			if (this.#nodesInGPU.has(nodeIdx)) return;
			let nodeData = this.#nodesInMemory.get(nodeIdx);
			if (nodeData) {
				this.setNodeVisible(nodeIdx, nodeData);
				return;
			}
			// In the most common scenario, this view actually needs the points of 'nodeIdx'.
			// A new PointCloud object is created and initialized for the received points.
			const p = this.#createPointCloudNode(nodeIdx, points);
			nodeData = new PointsCacheElement(p, points.size, performance.now());
			this.#nodesInMemory.set(nodeIdx, nodeData);
			this.setNodeVisible(nodeIdx, nodeData);
			this.nodeReady.emit(nodeIdx);
			this.#checkAllPointsReceived();
		} else {
			// No cameras' current visible nodes contains the node fetched
			this.lodTreeFetcher.releaseNode(nodeIdx, this);
		}
	}

	/** @returns the nodes in GPU */
	get nodesInGPU(): Map<number, PointsCacheElement> {
		return this.#nodesInGPU;
	}

	/** @returns the nodes in Memory */
	get nodesInMemory(): Map<number, PointsCacheElement> {
		return this.#nodesInMemory;
	}

	/** @returns the LOD tree */
	get tree(): LodTree {
		return this.lodTreeFetcher.tree;
	}

	/** @returns how many tree nodes are currently loaded in GPU and being rendered. */
	get renderedNodesCount(): number {
		return this.#nodesInGPU.size;
	}

	/** @returns how many tree nodes are in memory whether they are rendered or not. */
	get nodesInMemoryCount(): number {
		return this.#nodesInMemory.size;
	}

	/** @returns how many points are being rendered right now. */
	get totPointsInGPU(): number {
		return this.#totPointsInGPU;
	}

	/** @returns the object responsible for downloading the tree nodes. */
	get lodTreeFetcher(): LodTreeFetcher {
		return this.#lodFetcher;
	}

	/** @inheritdoc */
	override raycast(raycaster: Raycaster, intersects: Intersection[]): void {
		if (!this.raycasting.enabled) return;

		const startTime = performance.now();

		this.#matrixWorldInv.copy(this.matrixWorld).invert();
		this.#localRay.copy(raycaster.ray).applyMatrix4(this.#matrixWorldInv);

		if (this.material.clippingPlanes?.length === 6) {
			const planes = this.material.clippingPlanes;
			this.#clipFrustumWorld.set(planes[0], planes[1], planes[2], planes[3], planes[4], planes[5]);
			this.#clipFrustumLocal.copy(this.#clipFrustumWorld);
			for (const p of this.#clipFrustumLocal.planes) {
				p.applyMatrix4(this.#matrixWorldInv);
			}
		}
		const clipping = this.material.clippingPlanes?.length === 6;

		// Store the initial threshold value as we well update it depending on the density of the first leaf node
		const defThreshold = this.raycasting.threshold;
		let computedPickingTrees = 0;
		let isThresholdUpdated = false;
		// keeping track of the closest intersection found to the camera, and of its distance to the camera
		let minDistance = Number.POSITIVE_INFINITY;
		let closestIntersectionIdx = -1;

		this.#localIntersects.length = 0;

		/**
		 * Recursive function to pick on nodes using our heuristics
		 *
		 * @param node The node to check
		 * @param clippingHint hint about whether the node is inside, intersecting, or outside the clipping box
		 */
		const depthFirstSinglePickRaycast = (node: LodTreeNode, clippingHint: FrustumBoxCheck): void => {
			// If we passed the performance time budget bail out
			if (this.realTimeRaycasting && performance.now() - startTime >= this.raycasting.realtimeBudget) return;
			// If we reached max depth bail out
			if (node.depth > this.raycasting.maxDepth) return;
			// If we don't intersect this node bail out
			if (!this.#localRay.intersectBox(node.boundingBox, this.#raycastTarget)) return;

			if (clippingHint !== FrustumBoxCheck.BoxInside) {
				clippingHint = frustumIntersectsBox(this.#clipFrustumLocal, node.boundingBox);
				// If the clipping box is active and this node is outside of the box, bail out.
				if (clippingHint === FrustumBoxCheck.BoxOutside) return;
			}

			// Sort children by distance from the ray.
			// Computing the list of the node's children sorted by distance to the camera. In this way
			// the `distanceToPoint` function is executed only once per child, saving NlogN square roots.
			// The array below cannot be allocated outside this function, because otherwise the child node's array
			// overwrites the parent node's array.
			const sortedChildren = new Array<{ idx: number; distanceToPoint: number }>(node.children.length);
			for (let c = 0; c < node.children.length; ++c) {
				sortedChildren[c] = {
					idx: c,
					distanceToPoint: node.children[c].boundingBox.distanceToPoint(this.#localRay.origin),
				};
			}
			sortedChildren.sort((a, b) => a.distanceToPoint - b.distanceToPoint);

			if (this.raycasting.shouldReturnOnlyClosest) {
				for (const child of sortedChildren) {
					if (minDistance < child.distanceToPoint) break;
					// The parent node passes the clipping hint to the children, because
					// if the parent was inside the clipping box, also the children will be inside
					depthFirstSinglePickRaycast(node.children[child.idx], clippingHint);
				}
			} else {
				for (const child of sortedChildren) depthFirstSinglePickRaycast(node.children[child.idx], clippingHint);
			}

			const points = this.#nodesInGPU.get(node.id)?.points;
			if (points) {
				const origOptions = points.raycasting;
				points.raycasting = this.raycasting;

				// Limit the number of picking tree we can compute for every loop
				const needToComputePickingTree =
					points.pickingTree === undefined && this.raycasting.pickingTree.enabled;
				if (needToComputePickingTree) {
					if (this.realTimeRaycasting && computedPickingTrees >= this.raycasting.maxPickingTreesPerRaycast) {
						points.raycasting = origOptions;
						return;
					}

					computedPickingTrees += 1;
				}

				// The first time we visit a leaf node compute a threshold to use depending on the point density
				// of the leaf node
				if (!isThresholdUpdated) {
					this.raycasting.threshold = Math.max(computeLodNodePointDensity(this.tree, node) / 2, defThreshold);
					isThresholdUpdated = true;
				}

				const oldIntrCount = this.#localIntersects.length;
				// Updating the index to the closest intersection found so far.
				if (clippingHint === FrustumBoxCheck.BoxIntersects) {
					this.#preClippingIntersects.length = 0;
					points.raycast(raycaster, this.#preClippingIntersects);
					for (const i of this.#preClippingIntersects) {
						if (this.#clipFrustumWorld.containsPoint(i.point)) {
							this.#localIntersects.push(i);
							if (i.distance < minDistance) {
								minDistance = i.distance;
								closestIntersectionIdx = this.#localIntersects.length - 1;
							}
						}
					}
				} else {
					// If we enter here, all points are for sure inside the clipping box.
					points.raycast(raycaster, this.#localIntersects);
					for (let i = oldIntrCount; i < this.#localIntersects.length; ++i) {
						if (this.#localIntersects[i].distance < minDistance) {
							minDistance = this.#localIntersects[i].distance;
							closestIntersectionIdx = i;
						}
					}
				}
				points.raycasting = origOptions;
			}
		};

		// If there is a clipping box, we pass 'BoxIntersects' as clipping hint because is the most general,
		// so the actual intersection is computed.
		depthFirstSinglePickRaycast(
			this.tree.root,
			clipping ? FrustumBoxCheck.BoxIntersects : FrustumBoxCheck.BoxInside,
		);

		// Restore the threshold to the original value
		this.raycasting.threshold = defThreshold;

		// if no intersections, early return
		if (this.#localIntersects.length === 0) return;

		// If only the closest point is needed, no further sorting operations are needed.
		if (this.raycasting.shouldReturnOnlyClosest) {
			// When returning only the closest point optimize to get the closest to the ray in
			// a 100 mm range from the first hit and not only the closest to the camera
			const maxDistance = minDistance + 0.1;
			assert(closestIntersectionIdx >= 0);
			let bestIntersect = this.#localIntersects[closestIntersectionIdx];
			assert(bestIntersect.distanceToRay !== undefined);
			let bestDistanceToRay: number = bestIntersect.distanceToRay;
			for (const intersection of this.#localIntersects) {
				if (
					intersection.distance < maxDistance &&
					intersection.distanceToRay !== undefined &&
					intersection.distanceToRay < bestDistanceToRay
				) {
					bestIntersect = intersection;
					bestDistanceToRay = intersection.distanceToRay;
				}
			}
			this.#localIntersects[0] = bestIntersect;
			this.#localIntersects.length = 1;
		} else {
			// If a list of intersections is requested, a last sorting of the intersections is performed
			// to return them ordered by distance to the camera position.
			this.#localIntersects.sort((a, b) => a.distance - b.distance);
		}

		intersects.push(...this.#localIntersects);
	}

	/** @returns The material used to render all point cloud chunks */
	get material(): Material {
		return this.#material;
	}

	/** Sets the material used to render all point cloud chunks */
	set material(m: Material) {
		this.#material.dispose();
		this.#material = m;
		for (const p of this.#nodesInMemory.values()) {
			p.points.material = this.#material;
		}
	}

	/**
	 *
	 * @returns Whetehr subsampled rendering is enabled.
	 */
	getSubsampledRenderingOn(): boolean {
		return this.#subsampledRendering.enabled;
	}

	/**
	 * @param on New enablement state of subssampled rendering.
	 */
	setSubsampledRenderingOn(on: boolean): void {
		this.#subsampledRendering.enabled = on;
	}

	/**
	 *
	 * @returns The fraction of nodes to be rendered when subsampled rendering is on
	 */
	getSubsampledRenderingFraction(): number {
		return this.#subsampledRendering.fraction;
	}

	/**
	 * @param f The fraction of visible nodes that should be rendered.
	 * Accepted values range in [0, 1].
	 */
	setSubsampledRenderingFraction(f: number): void {
		this.#subsampledRendering.fraction = f;
	}

	/** @returns the maximum amount of LOD nodes that will be rendered when subsampled rendering is active */
	getSubsampledRenderingMaxNodes(): number {
		return this.#subsampledRendering.maxNodes;
	}

	/** @param m the maximum amount of LOD nodes that will be rendered when subsampled rendering is active */
	setSubsampledRenderingMaxNodes(m: number): void {
		this.#subsampledRendering.maxNodes = m;
	}
}
